import { Modal } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
import ApiLinks from '../../../actions/common/Api_Links'
import { sessionGet } from "../../../actions/common/TokenValidation";
import LoaderIcon from '../../../assets/icons/loader.png'

import axios from 'axios'

export const Upload = ({clientId, show, setShow}) => {
    const token = sessionGet('token')
    const [documentFile, setDocumentFile] = useState(null);
    const [documentTitle, setDocumentTitle] = useState('');
    const [documentDescription, setDocumentDescription] = useState('')
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('')

    const uploadFile = async (e) => {
        e.preventDefault()
        if (!(documentTitle)) return setMessage('Please provide title')
        if (!(documentDescription)) return setMessage('Please provide description')
        if (!documentFile) return setMessage('please provide file')

        if (!clientId) return console.log('please provide client id')

        

        // return console.log(clientId, documentFile, documentTitle, documentDescription)

        const formData = new FormData()
        formData.append('upload_statement_file', documentFile)
        formData.append('user_id', clientId);
        formData.append('statementTitle', documentTitle);
        formData.append('statementDescription', documentDescription)
        setLoading(true)
        setMessage('Sending, please wait...')
        try {
            const { data } = await axios.post(ApiLinks.uploadStatement, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            console.log(data)
            const event = new CustomEvent('upload-successful')
            window.dispatchEvent(event);
            setTimeout(() => {
                setShow(false)
            }, 2000)
            setLoading(false)
            setMessage('Successfully Uploaded')
        } catch (error) {
            setMessage(error?.response?.data?.msg || 'something went wrong, please try agin later.')
        }
    }

    return show === true && (
        <Modal
      show={show}
      className="modal bootstrap-dialog type-primary size-normal "
      role="dialog"
      aria-hidden="true"
      id="upload-modal"
      aria-labelledby="upload-modal"
      tabIndex={-1}
      onHide={() => {
        setShow(false)
      }}
    >
        <div className="upload-modal-container">
            <form onSubmit={e => uploadFile(e)}>
                <div className="input-group">
                    <label htmlFor="">Title (required)</label>
                    <input type="text" placeholder="Please Enter Title" onChange={e => setDocumentTitle(e.target.value)}/>
                </div>
                <div className="input-group">
                    <label htmlFor="">Brief Description (required)</label>
                    <textarea onChange={e => setDocumentDescription(e.target.value)}>

                    </textarea>
                    {/* <input type="text" placeholder="Description"/> */}
                </div>
                <div className="input-group" onChange={e => setDocumentFile(e.target.files[0])}>
                    <input type="file" name="" id="" />
                </div>
                <button type='submit'>{ loading ? 
                    <div className='loader-icon'><img src={LoaderIcon} alt="" /></div>:
                 'Submit'}</button>
            </form>
            <h5 style={{textAlign: 'center'}}> <em>{message}</em></h5>
        </div>

    </Modal>
    )
}