import React, { Component, Fragment, useEffect, useState } from "react";
import Header from "../Header";
import Leftsidebar from "../LeftSideBar";
import Footer from "../Footer";
import history from "../../stores/history";
import AddNewClient from "./modals/AddNewClientCompny";
import BulkUpload from "./modals/BulkUpload";
import EditClient from "./modals/EditClientCompany";
import DeleteClient from "./modals/DeleteClientCompany";
import ApproveClient from "./modals/ApproveClient";
import Nothing from "./modals/NothingSelected";
import Attachment from "./modals/AttachmentModal";
import * as personalActions from "../../actions/clientDetails/Personal";
import * as actions from "../../actions/Client";
import { connect } from "react-redux";
import moment from "moment";
import * as settings from "../../actions/Settings";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

function Client(props) {
  // let { history } = props;

  var table;
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    props.getList();
    props.getUserPermissionsList();
    props.getDocumentList();

    if (
      sessionStorage.getItem("permissions") &&
      sessionStorage.getItem("permissions").includes(15)
    ) {
      setEdit("inline-block");
    }

    if (
      sessionStorage.getItem("permissions") &&
      sessionStorage.getItem("permissions").includes(16)
    ) {
      setDelet("inline-block");
    }
  }, []);

  const [edit, setEdit] = useState("none");
  const [delet, setDelet] = useState("none");
  const [permissionError, setPermissionError] = useState(false);

  useEffect(() => {}, [props.clientList]);

  useEffect(() => {
    function format(d) {
      var splitdata,
        splitdata1,
        splitdata2,
        txtword = "";
      var odata = d.remarkstext;
      var odata1 = d.remarkstext;
      var odata2 = d.remarkstext;
      splitdata = odata.split("@");
      console.log(splitdata, "@");
      if (splitdata != undefined) {
        if (splitdata.length > 0) {
          if (splitdata[1] != undefined) {
            var chardata = splitdata[1].split(",");
            for (var kl = 0; kl < chardata.length; kl++) {
              txtword +=
                "Officer bearer " + chardata[kl] + " need to approve <br/>";
            }
          }
        }
      }

      splitdata1 = odata1.split("$");
      console.log(splitdata1, "$");
      if (splitdata1 != undefined) {
        if (splitdata1.length > 0) {
          if (splitdata1[1] != undefined) {
            var chardata1 = splitdata1[1].split(",");
            for (var kl = 0; kl < chardata1.length; kl++) {
              txtword +=
                "Deadline id: " + chardata1[kl] + " need to approve <br/>";
            }
          }
        }
      }
      splitdata2 = odata2.split("#");
      console.log(splitdata2, "#");
      if (splitdata2 != undefined) {
        if (splitdata2.length > 0) {
          if (splitdata2[1] != undefined) {
            var chardata2 = splitdata2[1].split(",");
            for (var kl = 0; kl < chardata2.length; kl++) {
              txtword +=
                "Charge no: " + chardata2[kl] + " need to approve <br/>";
            }
          }
        }
      }
      // `d` is the original data object for the row
      return (
        '<table cellpadding="7" cellspacing="0" border="0" style="width:100%;background-color:white;">' +
        "<tr>" +
        "<td>Remarks : " +
        d.remarks +
        " <br />" +
        txtword +
        "</td>" +
        "</tr>" +
        "<tr>" +
        "<td>Referral : " +
        d.referral +
        "</td>" +
        "</tr>" +
        "<tr>" +
        "<td>Address : " +
        d.address +
        "</td>" +
        "</tr>" +
        "</table>"
      );
    }

    $(document).ready(function () {
      table = $("#example").DataTable({
        pageLength: 25,
        lengthMenu: [25, 50],
        paging: true,
        //   destroy: true,
        //   dom: '<"top"ip>rt<"bottom"B><"clear">',
        //   buttons: [
        //     'copy',
        //     'excel',
        //     'pdf'
        // ]});
        destroy: true,
        // dom: "lBfrtip",
        // buttons: ["sdfs"],
        // // buttons:[{
        // //   text: 'Add',
        // //   action: function ( e, dt, node, config ) {
        // //       alert( 'Button activated' );
        // //     }
        // //   }
        // // ],
        data: props.clientList,
        columnDefs: [
          {
            targets: "_all",
            defaultContent: "",
            // "targets": 12,
          },
          {
            targets: 0,
            orderable: false,
            searchable: false,
            className: "details-control col-xs-1 ",
          },
          {
            targets: 1,
            orderable: false,
            searchable: false,
            className: "btnbox col-xs-1 col-sm-1 col-md-1 col-lg-1",
            data: "id",
            render: function (data, type, row) {
              // return (
              //   '<input class="selectBox" type="checkbox" value="' + data + '">'
              // );
              if (row.check == true) {
                return `<input class="check" id=check${row.id}  name=${row.id} title=${row.id} checked type="checkbox" />`;
              } else {
                return `<input class="check" id=check${row.id}  name=${row.id} title=${row.id} type="checkbox" />`;
              }
            },
          },
          {
            targets: 2,
            orderable: true,
            className: " col-xs-3 col-sm-3 col-md-3 col-lg-3",
            data: "company_name",
            id: "rl-name",
            render: function (data, type, row) {
              if (
                row.docids != "" &&
                row.docids != null &&
                row.docids != undefined
              ) {
                return `<span style="cursor: pointer;" class="company" id=${row.id} >${data}</span> <a >
              <span class="document glyphicon glyphicon-paperclip" id=${row.docids} /></a>`;
              } else {
                return `<span  style="cursor: pointer;" class="company" id=${row.id} >${data}</span>`;
              }
            },
          },
          {
            targets: 3,
            orderable: true,
            className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
            data: "registration_no",
          },
          {
            targets: 4,
            orderable: true,
            className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
            data: "incorporation_date",
            render: function (data, type, row) {
              let date = moment(data).format("DD/MM/YYYY");
              if (date != "Invalid date") {
                return `<span>${date}</span>`;
              } else {
                return `<span></span>`;
              }
            },
          },
          {
            targets: 5,
            orderable: true,
            className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
            data: "flag",
            render: function (data, type, row) {
              if (data == 0) {
                return '<span style="margin-left: 30px;"><span style="color:#000; font-weight: 600"> No flag </span></span>';
              } else if (data == 1) {
                return ` <span class="glyphicon glyphicon-flag" aria-hidden="true" title="Good crediential"  style="color: green" /> <span style="color:#000; font-weight: 600"> Good </span>`;
              } else if (data == 2) {
                return ` <span
              class="glyphicon glyphicon-flag"
              aria-hidden="true"
              style="color: orange"
            /> <span style="color:#000; font-weight: 600"> Bad </span>`;
              } else if (data == 3) {
                return `<span
               class="glyphicon glyphicon-flag"
               aria-hidden="true"
               style="color: red"
             /> <span style="color:#000; font-weight: 600"> Terrible </span>`;
              }
            },
          },

          {
            targets: 6,
            orderable: true,
            className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
            data: "statustext",
            render: function (data, type, row) {
              return data;
            },
          },
          {
            targets: 7,
            orderable: true,
            className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
            data: "id",
            render: function (data, type, row) {
              return `
              <button class="btnEdit btn btn-primary btn-xs" style="display:${edit}"  id=edit${data} title="Edit"  name=${data}  ><span class="glyphicon glyphicon-pencil" /></button>
              <button  class="btnDel btn btn-primary btn-xs" style="display:${delet}"  id=delete${data} title="Delete" name=${data}><span class="glyphicon glyphicon-trash" /></button>
              `;
            },
          },
          // {
          //   targets: 8,
          //   orderable: true,
          //   className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
          //   data: "id",
          //   render: function (data, type, row) {
          //     return `
          //     <button  class="btnAct btn btn-primary btn-xs" style="display:${delet}"  id=${data+"_1"} title="Active" name=${data+"_1"}>Active</button>
          //     <button  class="btnAct btn btn-primary btn-xs" style="display:${delet}"  title="InActive" name=${data+"_2"} id=${data+"_2"} >InActive</button>
          //     <button  class="btnAct btn btn-primary btn-xs" style="display:${delet}"  title="StrikeOff" id=${data+"_3"} name=${data+"_3"}>StrikeOff</button>
          //     <button  class="btnAct btn btn-primary btn-xs" style="display:${delet}"  title="TakeOver" id=${data+"_4"} name=${data+"_4"}>TakeOver</button>
          //     `;
          //   },
          // },
        ],
        rowCallback: function (row, data, index) {
          if (
            data.office_bearer_count > 0 ||
            data.client_charges_count > 0 ||
            data.client_deadlines_count > 0
          ) {
            $(row).css("background-color", "#f44242");
          }
        },
      });

      $("#example tbody").off("click");
      $("#example tbody").on("click", ".company", function (e) {
        console.log("event", e.target.id);
        var data = "";
        props.clientList.filter((fill) => {
          if (fill.id == e.target.id) data = fill;
        });
        if (
          sessionStorage.getItem("permissions") &&
          sessionStorage.getItem("permissions").includes(18)
        ) {
          sessionStorage.setItem("personalId", data.id);
          sessionStorage.setItem("selectedClient", data.company_name);
          history.push("/client_individual");
        } else {
          props.changeClientInfo("clientApiStatus", 2);
          setPermissionError(true);
          props.changeClientInfo("message", "Permission required");
          setTimeout(() => {
            props.changeClientInfo("clientApiStatus", 0);
            props.changeClientInfo("message", "");
          }, 2500);
        }
      });

      $("#example tbody").on("click", ".document", function (e) {
        var targetid = e.target.id;

        props.changeClientInfo("client_key", targetid);

        props.changeClientInfo("attachmentModal", true);
      });
      $("#example tbody").on("click", ".btnAct", function () {
        var data = this.name;
        var splitedstatus = data.split("_");
        var client_id = splitedstatus[0];
        var updated_status = splitedstatus[1];
        var data = {
          company_id: client_id,
          status: updated_status,
        };
        props.updatestatus(data);
      });
      $("#example tbody").on("click", ".btnEdit", function () {
        var data = table.row(this).data();
        props.clientList &&
          props.clientList.map((val, idx) => {
            if (val.id == this.name) {
              let incorp = new Date(val.incorporation_date);
              let doc_date = new Date(val.document_date);
              if (incorp != "Invalid Date") {
                props.changeClientInfo("incorporation_date", incorp);
              } else {
                props.changeClientInfo("incorporation_date", "");
              }
              if (doc_date != "Invalid Date") {
                props.changeClientInfo("document_date", doc_date);
              } else {
                props.changeClientInfo("document_date", "");
              }

              console.log("inco", incorp);

              props.changeClientInfo("company_name", val.company_name);

              props.changeClientInfo("registration_no", val.registration_no);
              props.changeClientInfo("currency", val.currency);
              props.changeClientInfo("address", val.address);
              props.changeClientInfo("business_address", val.business_address);
              props.changeClientInfo("referral", val.referral);
              props.changeClientInfo("remarks", val.remarks);
              props.changeClientInfo("flag", val.flag);
              props.changeClientInfo("document_id", val.document_id);
              // props.changeClientInfo("attachment", val.attachment);
              props.changeClientInfo(
                "attachment_keyword",
                val.attachment_keyword
              );
              props.changeClientInfo("document_type", val.document_type);
              props.changeClientInfo("notes", val.notes);
              props.changeClientInfo("clientstatus", val.status);
              props.changeClientInfo("company_id", val.id);
              props.changeClientInfo("company_edit_modal_show", true);
            }
          });
      });

      $("#example tbody").on("click", ".btnDel", function () {
        var data = table.row(this).data();
        props.changeClientInfo("company_id", this.name);
        props.changeClientInfo("deleteCompany", true);
      });
      $("#example tbody").on("click", ".btnprint", function () {
        var data = props.generatepdf(this.name);
        var nname = this.name;
      });

      $("#example tbody").on("click", ".btnbox", function (e) {
        var data = table.row(this).data();
        let arr = [...props.clientList];
        arr[table.row(this).index()].check = e.target.checked;
        // console.log("chinnu e", e.target.name, e.target.title, e.target.checked);
        // console.log("chinnu arr", arr);
        props.clientList &&
          props.clientList.map((key) => {
            if (key.id == e.target.title) {
              key.check = e.target.checked;
            } 
          });

        if (
          e.target.name != "" &&
          e.target.name != undefined &&
          e.target.checked == true
        ) {
          // console.log("chinnu checked true", e.target.name)
          var index = props.company_ids.indexOf(e.target.name);
          // console.log("chinnu index ", index, props.company_ids, e.target.name)
          if (index === -1) { 
            var rr = [...props.company_ids, e.target.name];
            // console.log("chinnu rr", rr)
            props.changeClientInfo("company_ids", rr);
            props.changeClientInfo("company_id", e.target.name);

          }
        } else {
          var index = props.company_ids.indexOf(`${e.target.name}`);
          // console.log("chinnu index else", index, props.company_ids, e.target.name)
          if (index !== -1) { 
            let aarr = props.company_ids
            aarr.splice(index, 1); 
            // console.log("chinnu aarr", aarr)
            props.changeClientInfo("company_ids", aarr);
            props.changeClientInfo("company_id", "");

          }
        }
        props.changeClientInfo("clientList", arr);
      });

      // Add event listener for opening and closing details
      $("#example tbody").on("click", "td.details-control", function () {
        var tr = $(this).closest("tr");
        console.log("data", tr);
        var row = table.row(tr);
        console.log("data", row);
        if (row.child.isShown()) {
          // This row is already open - close it
          row.child.hide();
          tr.removeClass("shown");
          $("#tr.shown td.details-control").toggleClass(
            "tr.shown td.details-control"
          );
        } else {
          // Open this row
          row.child(format(row.data())).show();
          tr.addClass("shown");
        }
      });
    });
  }, [props.clientList, props.company_ids]);

  useEffect(() => {
    console.log("permo", props.userPermissionsList);
    props.userPermissionsList &&
      props.userPermissionsList.forEach((item) => {
        var object = JSON.parse(item.permissions);
        // console.log("item", object);
        // object.length !=0 && object.forEach((permission) => {
        //   console.log("item", permission);
        // });
      });
  }, [props.userPermissionsList]);
  console.log("props.clientList", props.clientList);

  function handleExcel(){
    props.getDownloadExcel().then((responseData) => {
      window.open(`https://api.acctax.tech/${responseData.filename}`)
    });
  }
  return (
    <Fragment>
      <div className="wrapper">
        <Header />
        <Leftsidebar />

        <div
          className="content-wrapper"
          id="content-window"
          style={{ minHeight: 607 }}
        >
          <section className="content">
            {(() => {
              if (permissionError == true) {
                return props.clientApiStatus ? (
                  <div
                    className={`alert ${
                      props.clientApiStatus === 1 ? "" : "alert-danger"
                    }`}
                    role="alert"
                  >
                    {props.clientApiStatus === 1 ? "" : props.message}
                  </div>
                ) : (
                  ""
                );
              } else {
                return props.clientApiStatus ? (
                  <div
                    className={`alert ${
                      props.clientApiStatus === 1 ? "alert-success" : ""
                    }`}
                    role="alert"
                  >
                    {props.clientApiStatus === 1 ? props.message : ""}
                  </div>
                ) : (
                  ""
                );
              }
            })()}
            <div
              className="box box-primary box_align_left"
              // style={{ maxHeight: "100vh" }}
            >
              <div className="box-header">
                {" "}
                <i className="glyphicon glyphicon-list-alt" />
                <h3 className="box-title">Detailed Client List</h3>
                <div>
                  <nav className="navbar navbar-default" role="navigation">
                    <div className="container-fluid" id="navfluid">
                      <div className="navbar-header">
                        <button
                          type="button"
                          className="navbar-toggle"
                          data-toggle="collapse"
                          data-target="#navigationbar"
                          aria-expanded="false"
                        >
                          <span className="sr-only">Toggle navigation</span>
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                          <span className="icon-bar" />
                        </button>
                        <a className="navbar-brand" href="#" />
                      </div>
                      <div
                        className="collapse navbar-collapse"
                        id="navigationbar"
                      >
                        <ul className="nav navbar-nav">
                          <ul className="nav navbar-nav">
                            <li>
                              <a
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Clients
                                <span className="caret" />
                              </a>
                              <ul className="dropdown-menu">
                                {sessionStorage.getItem("permissions") &&
                                sessionStorage
                                  .getItem("permissions")
                                  .includes(14) ? (
                                  <>
                                    <li>
                                      <a
                                        onClick={() =>
                                          props.changeClientInfo(
                                            "company_modal_show",
                                            true
                                          )
                                        }
                                        role="menuitem"
                                        tabIndex={-1}
                                      >
                                        New
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          props.changeClientInfo(
                                            "bulk_upload",
                                            true
                                          )
                                        }
                                        role="menuitem"
                                        tabIndex={-1}
                                      >
                                        Bulk Upload
                                      </a>
                                    </li>
                                  </>
                                ) : null}
                              </ul>
                            </li>
                            <ul className="nav navbar-nav">
                              {sessionStorage.getItem("permissions") &&
                              sessionStorage
                                .getItem("permissions")
                                .includes(13) ? (
                                <li>
                                  <a
                                    href="#"
                                    className="dropdown-toggle"
                                    data-toggle="dropdown"
                                    role="button"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    View
                                    <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <a
                                        role="menuitem"
                                        tabIndex={-1}
                                        onClick={() => props.getList()}
                                      >
                                        Show All
                                      </a>
                                    </li>
                                    <li>
                                      <a onClick={() => props.getList(0)}>
                                        No Flag
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="menuitem"
                                        tabIndex={-1}
                                        onClick={() => props.getList(1)}
                                      >
                                        <span
                                          className="glyphicon glyphicon-flag"
                                          aria-hidden="true"
                                          style={{ color: "green" }}
                                        />
                                        Good Credential
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="menuitem"
                                        tabIndex={-1}
                                        onClick={() => props.getList(2)}
                                      >
                                        <span
                                          className="glyphicon glyphicon-flag"
                                          aria-hidden="true"
                                          style={{ color: "orange" }}
                                        />
                                        Bad Credential
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        role="menuitem"
                                        tabIndex={-1}
                                        onClick={() => props.getList(3)}
                                      >
                                        <span
                                          className="glyphicon glyphicon-flag"
                                          aria-hidden="true"
                                          style={{ color: "red" }}
                                        />
                                        Terrible Credential
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                              ) : null}
                              <ul className="nav navbar-nav">
                                <li>
                                  <a
                                    href="#"
                                    className="dropdown-toggle"
                                    data-toggle="dropdown"
                                    role="button"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    Approvals
                                    <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu">
                                    {sessionStorage.getItem("permissions") &&
                                    sessionStorage
                                      .getItem("permissions")
                                      .includes(17) ? (
                                      <li>
                                        <a
                                          className="button-approval"
                                          href="javascript:void()"
                                          data-title="Approve Selection"
                                          data-table="clients-table"
                                          data-selection="Approve"
                                          onClick={() => {
                                            if (props.company_id !== "") {
                                              props.changeClientInfo(
                                                "approve_modal",
                                                true
                                              );
                                            } else {
                                              props.sendValues(
                                                "nothing_select",
                                                true
                                              );
                                            }
                                          }}
                                        >
                                          Approve
                                        </a>
                                      </li>
                                    ) : null}
                                  </ul>
                                </li>
                              </ul>
                            </ul>
                          </ul>
                        </ul>
                        <ul className="nav navbar-nav">
                          <li>
                            {" "}
                            <a href="https://anymari-staging.s3.ap-southeast-1.amazonaws.com/1682077760498_Sample_Acctax_Template.xlsx" style={{ cursor: "pointer" }}>
                              Bulk Upload Template
                            </a>
                          </li>
                        </ul>
                        <ul className="nav navbar-nav">
                          <li onClick={()=> handleExcel()}>
                            {" "}
                            <a style={{ cursor: "pointer" }}>
                              Download Client List
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                  <div className="box-tools pull-right" />
                </div>
                <div />
              </div>
              <div className="box-body" style={{ minHeight: "100vh" }}>
                <div className="table-responsive">
                  <div
                    id="clients-table_wrapper"
                    className="dataTables_wrapper form-inline dt-bootstrap no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-striped table-bordered  col-md-12 dataTable no-footer"
                          role="grid"
                          id="example"
                          style={{ marginLeft: 0, padding: 0 }}
                        >
                          <thead>
                            <tr role="row">
                              <th
                                className="details-control col-xs-1 sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: 37 }}
                                aria-label="Details"
                              >
                                Details
                              </th>
                              <th
                                className="col-xs-1 select-checkbox sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: 32 }}
                                aria-label="Select"
                              >
                                Select
                              </th>
                              <th
                                className="col-xs-3 col-sm-3 col-md-3 col-lg-3 sorting_asc"
                                tabIndex={0}
                                aria-controls="clients-table"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: 118 }}
                                aria-sort="ascending"
                                aria-label="Company Name: activate to sort column descending"
                              >
                                Company Name
                              </th>
                              <th
                                className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting"
                                tabIndex={0}
                                aria-controls="clients-table"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: 48 }}
                                aria-label="Reg. No: activate to sort column ascending"
                              >
                                Reg. No / UEN
                              </th>
                              <th
                                className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting"
                                tabIndex={0}
                                aria-controls="clients-table"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: 36 }}
                                aria-label="Inc. Date: activate to sort column ascending"
                              >
                                Inc. Date
                              </th>
                              <th
                                className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting"
                                tabIndex={0}
                                aria-controls="clients-table"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: 22 }}
                                aria-label="Flag: activate to sort column ascending"
                              >
                                Flag
                              </th>
                              <th
                                className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: 40 }}
                                aria-label="Status"
                              >
                                Status
                              </th>
                              <th
                                className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: 40 }}
                                aria-label="Actions"
                              >
                                Actions
                              </th>
                              {/* <th
                                      className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{ width: 40 }}
                                      aria-label="Actions"
                                    >
                                      Update Status
                                    </th> */}
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>

                        <div
                          id="clients-table_processing"
                          className="dataTables_processing panel panel-default"
                          style={{ display: props.processModal }}
                        >
                          Processing...
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border"></div>
            </div>
          </section>
        </div>

        <Footer />
      </div>

      <AddNewClient />
      <BulkUpload />
      <EditClient />
      <DeleteClient />
      <ApproveClient />
      <Nothing />
      <Attachment />
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  console.log("chinnu company_ids", state.Client.company_ids)
  return {
    clientList: state.Client.clientList,
    client_key: state.Client.client_key,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    company_id: state.Client.company_id,
    company_ids: state.Client.company_ids,
    userPermissionsList: state.Client.userPermissionsList,
    processModal: state.Client.processModal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeClientInfo: (field, value) =>
      dispatch(actions.changeClientInfo(field, value)),
    sendValues: (f, v) => dispatch(personalActions.personal_data(f, v)),
    updatestatus: (data) => dispatch(actions.updatestatus(data)),
    getList: (data) => dispatch(actions.getClientList(data)),
    getDownloadExcel: ()=> dispatch(actions.clientListDownloadExcel()),
    getUserPermissionsList: () => dispatch(actions.getUserPermissionsList()),
    getDocumentList: () => dispatch(settings.getDocumentClassification()),
    generatepdf: (data) => dispatch(actions.generatepdf(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Client);
