import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../styles/CommenStyle.css";
import "./LeftSideBar.css";
import history from "../stores/history";
import { Link } from "react-router-dom";
import moment from "moment";
//import * as actions from "../actions/clientDetails/Document";
import {
  send_otp,
  changeClientInfo,
  validateOtp,
  list_document,
} from "../actions/Client";
//import * as settings from "../actions/Settings";
import queryString from "query-string";
import config from "../actions/common/Api_Links";

function ListDocument(props) {
  const { history, location = {} } = props;
  console.log("loca", location, props);
  const [otptext, setFile] = useState("");
  useEffect(() => {
    props.list_document(props.match.params.id);
  }, []);

  const AddNewFunc = (e) => {
    let input = {
      otptext: otptext,
      document_id: props.match.params.id,
    };
    //alert(input);
    var data = props.validateOtp(input);
  };
  const AddNewFunc_redirectSign = (id) => {
    let input = {
      otptext: otptext,
      document_id: props.match.params.id,
    };
    //alert(input);
    sessionStorage.setItem("agree_status", id);
    history.push("/sign_document/" + props.match.params.id);
  };
  console.log(props.otpstatus, "data");
  if (props.otpstatus == 0) {
    history.push("/");
  }
  // props.clientApiStatus == 3 && history.push("/sign_document/"+props.match.params.id)
  var decrypted_text = Buffer.from(props.match.params.id, "base64").toString(
    "ascii"
  );
  var spilttext = decrypted_text.split("_");
  return (
    <Fragment>
      <header className="navbar-default navbar-fixed-top">
        <div className="container-fluid">
          {/* Flash Message */}
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <span className="sr-only">Toggle Navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <a className="navbar-brand" href="/">
            <img className="logo-img" src="/assets/img/logo.svg" />
            </a>
            <a
              className="navbar-brand"
              href="#"
              style={{ position: "fixed", right: "0" }}
            >
              {spilttext[0]}
            </a>
          </div>
        </div>
      </header>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n        body {\n            padding-top: 60px;\n        }\n    ",
        }}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <div className="panel panel-default">
              <div className="panel-heading">Select Document List </div>
              <div className="panel-body">
                <div className="form-group">
                  {props.clientApiStatus ? (
                    <div
                      className={`alert ${
                        props.clientApiStatus == 3
                          ? "alert-success"
                          : "alert-danger"
                      }`}
                      role="alert"
                    >
                      {props.message}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-md-12">
                    {props.currencyList &&
                      props.currencyList.map((value, index) => {
                        return (
                          <>
                            {/* <input type="checkbox" name="checkbox[]" /> */}
                            <a href={config.siteUrl + value.file_path}>
                              {value.file_path
                                .split("/")
                                .pop()
                                .split("?")[0]
                                .split("#")[0]
                                .replace(/[/\\?%*:|"<>]/g, "-")}
                            </a>
                            <br />
                          </>
                        );
                      })}
                  </div>
                  <div className="form-group row" style={{ padding: "20px" }}>
                    <div className="col-md-5"></div>
                    <button
                      class="document  btn btn-primary "
                      title="Goto view and sign the document page"
                      onClick={(e) => AddNewFunc_redirectSign(1)}
                    >
                      Goto View/Sign Doc Page
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {/* <button
                      class="document  btn btn-primary  "
                      title="signature"
                      onClick={(e) => AddNewFunc_redirectSign(2)}
                    >
                      Partially Agree
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      class="document  btn btn-primary  "
                      title="signature"
                      onClick={(e) => AddNewFunc_redirectSign(3)}
                    >
                      DisAgree
                    </button> */}
                    {/* <button className="btn btn-primary" onClick={(e) => AddNewFunc(e)}>Add Remarks </button> &nbsp;&nbsp;&nbsp;&nbsp;
                      <button className="btn btn-primary" onClick={(e) => AddNewFunc_redirectSign(e)}>Sign Document </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    addNewDocumentModal: state.Document.addNewDocumentModal,
    tabSelected: state.Document.tabSelected,
    pageSelected: state.Client.pageSelected,
    document_id: state.Document.document_id,
    attachment_key: state.Document.attachment_key,
    document_type: state.Document.document_type,
    docDate: state.Document.docDate,
    notes: state.Document.notes,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    currencyList: state.Client.currencyList,
    //DocumentTypeList: state.Settings.DocumentTypeList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    //sendValues: (f, v) => dispatch(actions.documentFunction(f, v)),
    //getDocumentList: () => dispatch(settings.getDocumentClassification()),
    // changeClientInfo: (k, v) => dispatch(changeClientInfo(k, v)),
    // signUpload: (input) => dispatch(actions.signUpload(input)),
    list_document: (input) => dispatch(list_document(input)),
    validateOtp: (input) => dispatch(validateOtp(input)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListDocument);
