import React, { Fragment, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Leftsidebar from "../LeftSideBar";
import { connect } from "react-redux";
import { uploadFile } from "../../actions/common/S3_Upload";

function OCRUpload(props) {
  const [index, setIndex] = useState();
  function handleFile(e) {
    props.uploadFile(e);
  }
  function handleCopy(data, i) {
    var textBox = document.createElement("textarea");
    document.body.appendChild(textBox);
    textBox.value = data;
    textBox.select();
    document.execCommand("copy");
    document.body.removeChild(textBox);
    setIndex(i);
  }
  return (
    <Fragment>
      <div className="wrapper">
        <Header />
        <Leftsidebar />

        <div
          className="content-wrapper"
          id="content-window"
          style={{ minHeight: 607 }}
        >
          <section className="content">
            <div
              className="box box-primary box_align_left"
              // style={{ maxHeight: "100vh" }}
            >
              <div className="box-header" style={{ height: "50px" }}>
                <i className="glyphicon glyphicon-list-alt" />
                <h3 className="box-title">OCR Upload</h3>
                <div>
                  <div className="box-tools pull-right">
                    <div className={`uploadContain my-4 px-0 mx-auto`}>
                      <div className={"uploadBtnWrapper"}>
                        <button className={"btnUpload btn btn-primary"}>
                          Upload
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          multiple
                          onChange={(e) => handleFile(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div />
              </div>
              <div className="box-body" style={{ minHeight: "100vh" }}>
                <div
                  className="table-responsive"
                  style={{ overflowX: "unset" }}
                >
                  <div
                    id="clients-table_wrapper"
                    className="dataTables_wrapper form-inline dt-bootstrap no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table table-striped table-bordered  col-md-12 dataTable no-footer"
                          role="grid"
                          id="example"
                          style={{ marginLeft: 0, padding: 0 }}
                        >
                          <thead>
                            <tr role="row">
                              <th
                                className="details-control col-xs-1 sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: "5%" }}
                                aria-label="S.No"
                              >
                                S.No
                              </th>
                              <th
                                className="col-xs-1 select-checkbox sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: "75%" }}
                                aria-label="Link"
                              >
                                Link
                              </th>
                              <th
                                className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: "20%" }}
                                aria-label="Actions"
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.documents.length ? (
                              ""
                            ) : (
                              <tr>
                                <td colSpan={3}>No Data Found</td>
                              </tr>
                            )}
                            {props.documents.map((data, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{data} </td>
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => {
                                        handleCopy(data, i);
                                      }}
                                    >
                                      {index === i ? "Copied..." : "Copy"}
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer clearfix no-border"></div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    documents: state.Common.docs,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    uploadFile: (data) => dispatch(uploadFile(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OCRUpload);
