import config from "./common/Api_Links";
import { headers } from "./common/Headers";
import { ifTokenExpire } from "./common/TokenValidation";
import history from "../stores/history";
import { s3_file_upload } from "./common/S3_Upload";
import { after } from "lodash";
import { getDocumentClassification_new } from "./Settings";

export const updateClientErrors = (data) => {
  return {
    type: "UPDATE_CLIENT_ERRORS",
    data,
  };
};
export const settings_data = (field, value) => {
  return {
    type: "SETTINGS_DATA",
    field,
    value,
  };
};
export const changeClientInfo = (field, value) => {
  return {
    type: "CHANGE_CLIENT_INFO",
    field,
    value,
  };
};

export const client_list = (data) => {
  return {
    type: "CLIENT_LIST",
    data,
  };
};
export const all_document_client_list = (data) => {
  return {
    type: "ALL_DOCUMENT",
    data,
  };
};
export const all_document_client_list_show = (data) => {
  return {
    type: "ALL_DOCUMENT_SHOW",
    data,
  };
};
export const sign_list = (data) => {
  return {
    type: "SIGN_LIST",
    data,
  };
};
export const agree_list = (data) => {
  return {
    type: "AGREE_LIST",
    data,
  };
};
export const preview_sign = (data) => {
  return {
    type: "PREVIEW_SIGN",
    data,
  };
};

export const personal_data = (field, value) => {
  return {
    type: "PERSONAL_DATA",
    field,
    value,
  };
};


export const reset_client_info = () => {
  return {
    type: "RESET_CLIENT_INFO",
  };
};
export const DeleteDocumentList = (data) => {
  let value = { id: data };
  var header = headers();
  return (dispatch) => {
    return fetch(config.deletelistdocumentbyid+data, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeClientInfo("deleteCompany", false))
            dispatch(changeClientInfo("docId", ''))
            
          } else {
            dispatch(changeClientInfo("docApiStatus", 2));
            dispatch(changeClientInfo("message", responseData.message));

          }
        }
      });
  };
};
export const DeleteDocumentFolder = (data) => {
  let value = { id: data };
  var header = headers();
  return (dispatch) => {
    return fetch(config.deletelistFolder+data, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeClientInfo("deleteCompany", false))
            dispatch(changeClientInfo("docId", ''))
            dispatch(changeClientInfo("docFlag", ''))
            dispatch(getDocumentClassification_new())
            
          } else {
            dispatch(changeClientInfo("docApiStatus", 2));
            dispatch(changeClientInfo("message", responseData.message));

          }
        }
      });
  };
};

export const updatestatus = (data) => {
  var header = headers();
  return async (dispatch) => {
      return fetch(config.updatestatus, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              dispatch(changeClientInfo("company_modal_show", false));
              // dispatch(changeClientInfo("addClientErrors", {}));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(getClientList());
              }, 1500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 1500);
            }
          }
        });
   
  };
};




export const addClient = (data) => {
  var header = headers();
  return async (dispatch) => {
    var response = {
      status: 1,
    };
    if (data.attachment != "") {
      response = await s3_file_upload(data);
    }
    console.log("persona", response);
    if (response.status === 1) {
      data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.add_client, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              dispatch(changeClientInfo("company_modal_show", false));
              // dispatch(changeClientInfo("addClientErrors", {}));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(getClientList());
                window.location.reload();
              }, 1500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 1500);
            }
          }
        });
    } else {
      dispatch(changeClientInfo("clientApiStatus", 2));
      dispatch(changeClientInfo("message", response.message));
      setTimeout(() => {
        dispatch(changeClientInfo("clientApiStatus", 0));
        dispatch(changeClientInfo("message", ""));
      }, 1500);
    }
  };
};

export const addBulkClient = (data) => {
  var header = headers();
  return async (dispatch) => {
    const formData = new FormData();
    const {attachment } = data;
    formData.append("upload_file", attachment);
    return fetch(config.add_bulk_client, {
      method: "POST",
      body: formData,
      headers: {Authorization : header.Authorization},
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeClientInfo("bulk_upload", false));
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 1));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(getClientList());
            }, 1500);
          } else {
            dispatch(changeClientInfo("clientApiStatus", 2));
            dispatch(changeClientInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
            }, 1500);
          }
        }
      });
  };
};



export const getClientList = (input) => {
  var header = headers();
  let url;
  if (input != undefined) {
    url = config.getclients+"?flag=" + input;
  } else {
    url = config.client_list;
  }
  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(url, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            if (responseData.status === 1) {
              let add = [...responseData.data];
              responseData.data.map((val, idx) => {
                val.check = false;
              });
              dispatch(client_list(add));
              dispatch(changeClientInfo("processModal", "none"));
            }
          } else {
            dispatch(client_list([]));
          }
        }
      });
  };
};

export const clientListDownloadExcel = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.dw_client_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        return responseData
      });
  };
};

export const editClient = (data) => {
  var header = headers();
  return async (dispatch) => {
    var response = {
      status: 1,
    };
    console.log("data", data);
    if (data.attachment != "") {
      console.log("here");
      response = await s3_file_upload(data);
    }
    console.log("persona", response);
    if (response.status === 1) {
      data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.Edit_client, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              dispatch(changeClientInfo("company_edit_modal_show", false));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(changeClientInfo("clientApiStatus", 1));
              dispatch(changeClientInfo("company_id", ''));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(getClientList());
              }, 2500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    } else {
      dispatch(changeClientInfo("clientApiStatus", 2));
      dispatch(changeClientInfo("message", response.message));
      setTimeout(() => {
        dispatch(changeClientInfo("clientApiStatus", 0));
        dispatch(changeClientInfo("message", ""));
      }, 1500);
    }
  };
};

export const deleteClient = (data) => {
  let value = { id: data.id };
  var header = headers();
  return (dispatch) => {
    return fetch(config.delete_client, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeClientInfo("deleteCompany", false));
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 1));
            dispatch(changeClientInfo("company_id", ''));
            dispatch(getClientList());
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
            }, 2000);
          } else {
            dispatch(changeClientInfo("clientApiStatus", 2));
            dispatch(changeClientInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
            }, 2000);
          }
        }
      });
  };
};
export const saveDocumentNotes = (val,notesdata,document_id) => {
  let url;
  if (val != undefined && val != null) {
    url = config.savenotes+`?client_id=${sessionStorage.getItem(
      "personalId"
    )}&status=${val}&notes=${notesdata}&document_id=${document_id}`;
  } else {
    url =  config.savenotes+`?client_id=${sessionStorage.getItem(
      "personalId"
    )}&status=${val}&notes=${notesdata}&document_id=${document_id}`;
  }
  var header = headers();
  return (dispatch) => {
    return fetch(url, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 1));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
            }, 2000);
          } else {
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 0));
          }
        }
      });
  };
};
export const fetchDocumentNotes = (val,notesdata,document_id) => {
  let url;
  if (val != undefined && val != null) {
    url = config.getnotes+`?client_id=${sessionStorage.getItem(
      "personalId"
    )}&status=${val}&notes=${notesdata}&document_id=${document_id}`;
  } else {
    url =  config.getnotes+`?client_id=${sessionStorage.getItem(
      "personalId"
    )}&status=${val}&notes=${notesdata}&document_id=${document_id}`;
  }
  var header = headers();
  return (dispatch) => {
    return fetch(url, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeClientInfo("updated_comment", responseData.data));
        
          } else {
            dispatch(changeClientInfo("updated_comment", ""));
           
          }
        }
      });
  };
};

export const getClientStatus = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_client_status_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("List1", responseData.data));
          } else {
            dispatch(settings_data("List1", []));
          }
        }
      });
  };
};

export const getFlag = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.getFlagList, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(personal_data("flagList", responseData.data));
          }
        }
      });
  };
};

export const getClientIdList = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.client_id_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(settings_data("clientIdList", responseData.data));
          } else {
            dispatch(settings_data("clientIdList", []));
          }
        }
      });
  };
};
export const getCurrencyList = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_currency_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeClientInfo("currencyList", responseData.data));
          } else {
            dispatch(changeClientInfo("currencyList", []));
          }
        }
      });
  };
};

export const allListDocuments = (val) => {
  let value =config.list_alldocs_byclient +`?client_id=${sessionStorage.getItem(
    "personalId"
  )}`;
  var header = headers();
  return (dispatch) => {
    return fetch(value, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            
            dispatch(all_document_client_list(responseData.data));
          } else {
            dispatch(all_document_client_list([]));
          }
        }
      });
  };
};

export const allListDocuments_uploaded = (val) => {
  let value =config.list_alldocs_byclient_docs +`?client_id=`+val;
  var header = headers();
  return (dispatch) => {
    return fetch(value, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            
            dispatch(all_document_client_list_show(responseData.data));
          } else {
            dispatch(all_document_client_list_show([]));
          }
        }
      });
  };
};
export const DeleteDocument = (val) => {
  let value =config.deletedocumentbyid +val;
  var header = headers();
  return (dispatch) => {
    return fetch(value, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 3));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
              dispatch(getClientList())
            }, 2000);
          } else {
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 0));
          }
        }
      });
  };
};

export const approveClient = (val) => {
  var header = headers();
  let value = { client_id: val };

  return (dispatch) => {
    return fetch(config.approve_client, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData.status === 1) {
          ifTokenExpire(responseData);
          dispatch(changeClientInfo("approve_modal", false));
          dispatch(changeClientInfo("message", responseData.message));
          dispatch(changeClientInfo("clientApiStatus", 1));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("company_id", ''));
            dispatch(getClientList());
          }, 1500);
        } else {
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", responseData.message));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 1500);
        }
      });
  };
};

export const approveClientIds = (val) => {
  var header = headers();
  let value = { id: val };

  return (dispatch) => {
    return fetch(config.approve_client_ids, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData.status === 1) {
          ifTokenExpire(responseData);
          dispatch(changeClientInfo("approve_modal", false));
          dispatch(changeClientInfo("message", responseData.message));
          dispatch(changeClientInfo("clientApiStatus", 1));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("company_id", ''));
            dispatch(getClientList());
          }, 1500);
        } else {
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", responseData.message));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 1500);
        }
      });
  };
};

export const getUserPermissionsList = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.user_permissions_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(
              changeClientInfo("userPermissionsList", responseData.data)
            );
          } else {
            dispatch(changeClientInfo("userPermissionsList", []));
          }
        }
      });
  };
};

export const generatepdf = (data) => {
  var header = headers();
  let value = { client_id: data };

  return (dispatch) => {
    return fetch(config.generatepdf_client, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    }) .then((response) => {
      if (response) {
        return response.json();
      }
    })
      .then((responseData) => {
        if (responseData) {
          console.log("responseData",responseData)
          if (responseData.status === 1) {
            console.log("responseData2",responseData)
            var randNumber = Math.floor(Math.random()*99);
            window.open(config.siteUrl+"pdfpath/"+data+"_Client.pdf?"+randNumber, '_blank');
            return responseData.basepath+"/"+ responseData.pdfname;
            
          }else{
            return responseData.message;
          }
          //return response.json();
        }
      })
    
  };
};
export const list_document = (data) => {
  var header = headers();
  let value = { document_id: data };

  return (dispatch) => {
    return fetch(config.getdocument_list_by_otp, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          console.log(response,"response")
          if (response.status === 1) {
            return response;
          }else{
           return response;
          }
          //return response.json();
        }
      })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeClientInfo("currencyList", responseData.data));
          } else {
            dispatch(changeClientInfo("currencyList", []));
          }
        }
      });
    
  };
};
export const send_otp = (data) => {
  var header = headers();
  let value = { document_id: data };

  return (dispatch) => {
    return fetch(config.send_otp, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          console.log(response,"response")
          if (response.status === 1) {
            return response;
          }else{
           return response;
          }
          //return response.json();
        }
      })
    
  };
};
export const check_otpvalidation = (data) => {
  var header = headers();
  let value = { document_id: data };

  return (dispatch) => {
    return fetch(config.check_otpvalidation, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          if (responseData.status === 1) {
            dispatch(changeClientInfo("otpstatus", 1));
          } else {
            dispatch(changeClientInfo("otpstatus", 0));
          }
        }
      });
    
  };
};
export const documentList = (data) => {
  var header = headers();
  let value = { document_id: data };

  return (dispatch) => {
    return fetch(config.docList, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        console.log("responseData",responseData)
        if (responseData) {
          if (responseData.status === 1) {
            dispatch(changeClientInfo("documentlist","https://api.acctax.tech"+responseData.data));
          } else {
            dispatch(changeClientInfo("documentlist", ""));
          }
        }
      });
    
  };
};


export const agreeList_BYID = (data) => {
  var header = headers();
  let value = { document_id: data };

  return (dispatch) => {
    return fetch(config.agreeList_byId, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        console.log("responseData",responseData)
        if (responseData) {
          if (responseData.status === 1) {
            dispatch(agree_list(responseData.data));
          } else {
            dispatch(agree_list([]));
          }
        }
      });
    
  };
};
export const documentList_BYID = (data) => {
  var header = headers();
  let value = { document_id: data };

  return (dispatch) => {
    return fetch(config.docList_byId, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        console.log("responseData",responseData)
        if (responseData) {
          if (responseData.status === 1) {
            dispatch(changeClientInfo("documentlist","https://api.acctax.tech"+responseData.data));
          } else {
            dispatch(changeClientInfo("documentlist", ""));
          }
        }
      });
    
  };
};

export const signatureList_byuser = (data) => {
  var header = headers();
  let value = { document_id: data };

  return (dispatch) => {
    return fetch(config.signatureList_Byuser, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          console.log("signatureListdata",responseData)
          if (responseData.status === 1) {
            let add = [...responseData.data];
            console.log(data, "Signature list")
            dispatch(sign_list(add));
          } else {
            dispatch(sign_list([]));
            dispatch(changeClientInfo("otpstatus", 0));
          }
        }
      });
    
  };
};


export const signatureList = (data) => {
  var header = headers();
  let value = { document_id: data };

  return (dispatch) => {
    return fetch(config.signatureList, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          console.log("signatureListdata",responseData)
          if (responseData.status === 1) {
            let add = [...responseData.data];
            console.log(data, "Signature list")
            dispatch(sign_list(add));
          } else {
            dispatch(sign_list([]));
            dispatch(changeClientInfo("otpstatus", 0));
          }
        }
      });
    
  };
};
export const validateOtp = (data) => {
  var header = headers();
  let value = data;

  return (dispatch) => {
    return fetch(config.validate_otp, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    }).then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          console.log(responseData,"response")
          if (responseData.status === 1) {
           
              dispatch(changeClientInfo("clientApiStatus", 3));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus",0));
                dispatch(changeClientInfo("message", "Valid OTP"));
              }, 1500);
               return 1;
          }else{
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", "Invalid OTP"));
              }, 1500);
                return 0;
          }
          //return response.json();
        }
      })
    
  };
};
