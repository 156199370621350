import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import ScrollTop from "./ScrollTop";
import CompanySetups from "./components/administration/CompanySetups";
import GlobalDocuments from "./components/administration/GlobalDocuments";
import DocumentSetup from "./components/administration/DocumentSetup";
import DocumentId from "./components/administration/DocumentId";

import UserManagement from "./components/userManagement/UserManagementMain";
import Profile from "./components/Profile";
import Settings from "./components/Settings";
import ShareHolder from "./components/client/OfficeShareHoder";
import DeadlineSummary from "./components/client/DeadlineSummary";
import ClientIndividualList from "./components/client/ClientListMainPage";
import Login from "./components/Login";
import ForgetPassword from "./components/ForgetPassword";
import ClientDetails from "./components/client/ClientDetails";
import Dashboard  from "./components/client/Dashboard";
import { loginDetails, sendData } from "./actions/Login";
import DocumentManagement from "./components/client/DocumentManagement";
import PendingDocuments from "./components/client/PendingDocuments";
import CompletedDocuments from "./components/client/CompletedDocuments";
import PendingDocument from "./components/client/PendingDocument";
import CompletedDocument from "./components/client/CompletedDocument";
import SignUploadPage from "./components/SignUploadPage";
import SignDocument from './components/client/SignDocument'
import OtpPage from "./components/OtpPage";
import ListDocument from "./components/ListDocument";
import {StatementUpload} from './components/client/StatementUpload'
import OCRUpload from "./components/ocr/OCRUpload";

const Routes = (props) => {
  const { history, location = {} } = props;
  useEffect(() => {
    if (props.fromPathName == "") {
      props.sendLogin("fromPathName", location.pathname);
    } else {
      props.sendLogin("fromPathName", props.pathName);
    }
    props.sendLogin("pathName", location.pathname);
  }, [location.pathname]);
  return (
    <Fragment>
      <ScrollTop />
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/forgetpassword" component={ForgetPassword} />
        <Route exact path="/company_setup" component={CompanySetups} />
        <Route exact path="/global_documents" component={GlobalDocuments} />
        <Route exact path="/document_templates" component={DocumentSetup} />
        <Route exact path="/document_by_id/:id" component={DocumentId} />
        
        <Route exact path="/user_management" component={UserManagement} />
        <Route exact path="/completed_document" component={CompletedDocument} />
        <Route exact path="/pending_document" component={PendingDocument} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/settings" component={Settings} />
        <Route exact path="/share_holder" component={ShareHolder} />
        <Route exact path="/deadline_summary" component={DeadlineSummary} />
        <Route exact path="/client_list" component={ClientDetails} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/client_individual" component={ClientIndividualList} />
        <Route exact path='/ocr-upload' component={OCRUpload}/>
        <Route exact path="/pending_docs_user" component={PendingDocuments} />
        <Route exact path="/completed_docs_user" component={CompletedDocuments} />
        <Route exact path="/manage_document" component={DocumentManagement} />
        <Route exact path="/sign_document/:id" component={SignUploadPage} />
        <Route exact path="/signdocument" component={SignDocument} />
        <Route exact path="/otp_screen/:id" component={OtpPage} />
        <Route exact path="/listing_document/:id" component={ListDocument} />
        <Route exact path='/upload_statement' component={StatementUpload}/>
      </Switch>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    pathName: state.Login.pathName,
    fromPathName: state.Login.fromPathName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendLogin: (f, v) => dispatch(loginDetails(f, v)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
