import React, { Fragment, useEffect,useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import Leftsidebar from "../LeftSideBar";
import '../../styles/statement-upload.css'
import { Upload } from "./modals/UploadStatement";
import axios from 'axios'
import ApiLinks from '../../actions/common/Api_Links'
import { sessionGet } from "../../actions/common/TokenValidation";
import { StatementTable } from './modals/StatementTable'
import Swal from "sweetalert2";

export const StatementUpload = () => {
    const [showUploadModal, setShowUploadModal] = useState(false)
    const [showTableModal, setShowTableModal] = useState(false)
    const [selectedClientId, setSelectedClientId] = useState(null)
    const [clientList, setClientList] = useState([]);
    const [statementsList, setStatementsList] = useState([])
    const [downloadFormat, setDownloadFormat] = useState('pdf-format');
    const [selectedTableDetails, setSelectedTableDetails] = useState(null)
    const token = sessionGet('token')

    const getClientList = async () => {
        try{
            const { data } = await axios.get(ApiLinks.client_list, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setClientList(data.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const getStatementsList = async () => {
        try {
            const { data } = await axios.post(ApiLinks.getStatements, {user_id: selectedClientId}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            console.log(data)
            setStatementsList(data.result)
        }
        catch (error) {
            console.log(error)
        }
    }

    const downloadDocument = async (documentId, title) => {
        if (!documentId || !selectedClientId) console.log('please provided both documentId and clientId')
        try {
            const response = await axios({
              method: 'post',
              url: ApiLinks.downloadStatementDoc,
              data: {
                document_id: documentId, user_id: selectedClientId
              },
              responseType: 'blob',
              headers: {
                Authorization: `Bearer ${token}`,
                // 'Content-Type': 'text/csv'
              }
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${title}.csv`);
            document.body.appendChild(link);
            link.click();
          } catch (error) {
            console.error('Error downloading file:', error);
          }
    }

    const handleDownloadFormat = async () => {
        if (!downloadFormat) console.log('please provide download format')
        const downloadFileTitle = downloadFormat === 'pdf-format' ? 'template.pdf' : 'template.docx'
        try {
            const response = await axios({
              method: 'post',
              url: ApiLinks.downloadStatementSample,
              data: {
                documentFormat: downloadFormat
              },
              responseType: 'blob',
              headers: {
                Authorization: `Bearer ${token}`,
                // 'Content-Type': 'text/csv'
              }
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadFileTitle);
            document.body.appendChild(link);
            link.click();
          } catch (error) {
            console.error('Error downloading file:', error);
          }
    }

    const deleteDocument = async ( document_id ) => {
        if (!document_id) return console.log('please provide document_id')
        try {
            const { data } = await axios.post(ApiLinks.deleteStatement, { document_id }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            console.log(data)
            getStatementsList()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (!selectedClientId) return
        getStatementsList()
    }, [selectedClientId])

    useEffect(() => {
        const listener = () => {
            getStatementsList()
        }

        window.addEventListener('upload-successful', listener)

        return () => window.removeEventListener('upload-successful', listener)
    })

    useEffect(() => {
        getClientList()
    }, [])

    useEffect(() => {
        if (clientList.length < 1) return
        if (selectedClientId) return
        setSelectedClientId(clientList[0].id)
    }, [clientList])

    useEffect(() => {
        console.log(selectedClientId)
    }, [selectedClientId])

    return (
        <>
        <div className="wrapper">
            <Header />
            <Leftsidebar />
            <div className="content-wrapper statement-upload">
                <section className="content">
                    <div className="input-group client">
                        <label htmlFor="">Client</label> :
                        <select value={selectedClientId} onChange={e => setSelectedClientId(e.target.value)}>
                            {
                                clientList.length > 0 && clientList.map((client, index) => {
                                    return <option key={index} value={client.id}>{client.company_name}</option>
                                })
                            }
                        </select>
                    </div>
                    {/* <div className="input-group sample">
                        <label htmlFor="">Download Sample</label> :
                        <select value={downloadFormat} onChange={e => setDownloadFormat(e.target.value)}>
                            <option value="word-format">word format</option>
                            <option value="pdf-format">pdf format</option>
                        </select>
                        <button onClick={handleDownloadFormat}>Download</button>
                    </div> */}
                    <div className="upload-btn-container">
                        <button className="upload" onClick={() => setShowUploadModal(true)}>
                            Upload
                        </button>
                    </div>
                    <div className="statement-list-container">
                        {statementsList.length > 0 ?
                        <>
                        <h2>Statement List ({clientList.find(client => client.id == selectedClientId)['company_name']})</h2>
                        <table>
                            <thead>
                                <td></td>
                                <td>Title</td>
                                <td>Client</td>
                                <td>Download</td>
                            </thead>
                            <tbody>
                                {
                                    statementsList.map((statement, index) => {
                                        return <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{statement.title}</td>
                                            <td>{clientList.find(client => client.id == selectedClientId)['company_name']}</td>
                                            <td className="download-sample" >
                                                <div className="icons">
                                                    <span className="icon" onClick={() => downloadDocument(statement.id, statement.title)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                                        </svg>
                                                    </span>
                                                    <span className="icon" onClick={() => {
                                                        setSelectedTableDetails({
                                                        document_id: statement.id,
                                                        user_id: statement.user_id,
                                                        })
                                                        setShowTableModal(true)
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                                        </svg>
                                                    </span>
                                                    <span className="icon" onClick={() => {
                                                        Swal.fire({
                                                            customClass: {
                                                              popup: 'my-swal-modal',
                                                              content: 'my-swal-content',
                                                            },
                                                            title: 'Delete Statement',
                                                            text: `Are you sure you want to delete this statement?`,
                                                            showDenyButton: true,
                                                            showCancelButton: false,
                                                            confirmButtonText: 'Delete',
                                                            denyButtonText: `Cancel`,
                                                          }).then(result => {
                                                            if (result.isConfirmed) {
                                                                deleteDocument(statement.id)
                                                            }
                                                          })
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        </>
                        :
                        (clientList.length > 0 && selectedClientId) ?
                        <h4 className="no-statement-msg">No statement uploaded. Click the upload button to upload <em> {`for ${clientList.find(client => client.id == selectedClientId)['company_name']}`}</em> </h4> :
                        null
                        }
                    </div>
                </section>
            </div>
            <Footer />
        </div>
        <Upload show={showUploadModal} setShow={setShowUploadModal} clientId={selectedClientId}/>
        {selectedTableDetails && <StatementTable show={showTableModal} setShow={setShowTableModal} selectedTableDetails={selectedTableDetails}/>}
        </>
    )
}