import React, { Fragment, useEffect,useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import {
  charges,
  getChargesList,
  getCurrencyList,
} from "../../../actions/clientDetails/Charges";
import * as personalActions from "../../../actions/clientDetails/Personal";
import * as client from "../../../actions/Client";
import AddCharges from "../modals/AddCharges";
import Nothing from "../modals/NothingSelected";
import ApproveCharges from "../modals/ApproveCharges";
import DeleteCharges from "../modals/DeleteCharges";
import EditCharges from "../modals/EditCharges";
import Attachment from "../modals/AttachmentModal";
import * as settings from "../../../actions/Settings";
import $ from "jquery";
import moment from "moment";

function Charges(props) {
  var table;
  useEffect(() => {
    props.getCurrencyList();
    props.getDocumentList();
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });
    props.getChargesList();

    if (sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(32)) {
      setEdit("inline-block")
    };

    if (sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(33)) {
      setDelet("inline-block")
    };

  }, []);

  const [edit,setEdit] = useState ("none");
  const [delet,setDelet] = useState ("none");

  useEffect(() => {
    function format(d) {
      return (
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;width:100%">' +
        "<tr>" +
        "<td>Address:</td>" +
        "<td>" +
        d.address +
        "</td>" +
        "</tr>" +
        "<tr>" +
        "<td>Email:</td>" +
        "<td>" +
        d.email +
        "</td>" +
        "</tr>" +
        "<tr>" +
        "<td>Mobile:</td>" +
        "<td>" +
        d.phone +
        "</td>" +
        "</tr>" +
        "</table>"
      );
    }

    $(document).ready(function () {
      table = $("#charges").DataTable({
        pageLength:25,
        lengthMenu: [ 25, 50 ],
        paging: true,
        destroy: true,
        dom: '<"top"lBf>rt<"bottom"ip><"clear">',
        data: props.chargeList,
        select: {
          style: "multi",
          selector: "td:first-child>input",
        },
        fixedHeader: {
          header: true,
          headerOffset: 50,
        },
        columnDefs: [
          {
            targets: "_all",
            defaultContent: "",
            // "targets": 12,
          },

          {
            targets: 0,
            orderable: false,
            searchable: false,
            className: "btnbox col-xs-1 col-sm-1 col-md-1 col-lg-1",
            data: "id",
            render: function (data, type, row) {
              // return (
              //   '<input class="selectBox" type="checkbox" value="' + data + '">'
              // );
              if (row.check == true) {
                return `<input class="check" id=check${row.id}  name=""  checked type="checkbox" />`;
              } else {
                return `<input class="check" id=check${row.id}  name=${row.id}   type="checkbox" />`;
              }
            },
          },
          {
            targets: 1,
            orderable: true,
            className: "col-xs-1 col-sm-1 col-md-1 col-lg-1 ",
            data: "charge_no",
            render: function (data, type, row) {
              if(row.document_link != '' && row.document_link != null && row.document_link != undefined){
                return `<span>${data}</span><a><span class="document glyphicon glyphicon-paperclip" id=${row.document_link}  /></a>`}
                else {
                  if(row.docids != '' && row.docids != null && row.docids != undefined){
                    return `<span>${data}</span><a><span class="document glyphicon glyphicon-paperclip" id=${row.docids}  /></a>`}
                    else{
                      return `<span>${data}</span>`
                    }
                  
                  
                }
            },
          },
          {
            targets: 2,
            orderable: true,
            className: " col-xs-2 col-sm-1 col-md-1 col-lg-1",
            data: "date_registered",
            render: function (data, type, row) {
              if (moment(data).format("DD/MM/YYYY") != "Invalid date") {
                return `<span>${moment(data).format("DD/MM/YYYY")}</span>`;
              } else {
                return '<span style="margin-left: 30px;">---</span>';
              }
            },
          },
          {
            targets: 3,
            orderable: true,
            className: " col-xs-2 col-sm-1 col-md-2 col-lg-1",
            data: "date_discharge",
            render: function (data, type, row) {
              if (moment(data).format("DD/MM/YYYY") != "Invalid date") {
                return `<span>${moment(data).format("DD/MM/YYYY")}</span>`;
              } else {
                return '<span style="margin-left: 30px;">---</span>';
              }
            },
          },
          {
            targets: 4,
            orderable: true,
            className: "hidden-xs hidden-sm hidden-md col-lg-1 ",
            data: "currency_id",
            render: function (data, type, row) {
              let activeName = props.currencyList.filter(
                (item) => item.id == data
              );
              if (activeName.length > 0) {
                return `<span>${activeName[0].Name}</span>`;
              } else {
                return data;
              }
            },
          },
          {
            targets: 5,
            orderable: true,
            className: " col-xs-2 col-sm-2 col-md-2 col-lg-1 ",
            data: "amount_secured",
          },
          {
            targets: 6,
            orderable: true,
            className: " col-xs-2 col-sm-2 col-md-2 col-lg-2 ",
            data: "chargee",
          },

          {
            targets: 7,
            orderable: true,
            className: "col-xs-2 col-sm-2 col-md-2 col-lg-1",
            data: "id",
            render: function (data, type, row) {
                return`<button class="btnEdit btn btn-primary btn-xs" style="display:${edit}" id=edit${data} title="Edit" name=${data} onClick="console.log('Hello',title)" ><span class="glyphicon glyphicon-pencil" /></button>
                <button class="btnDel btn btn-primary btn-xs" style="display:${delet}" id=delete${data} title="Delete"  name=${data} data-toggle="modal" data-target="#deletemodal""><span class="glyphicon glyphicon-trash" /></button>`
 
            },
          },
        ],
        rowCallback: function (row, data, index) {
          if (!data.is_approved) {
            $(row).css("background-color", "#f44242");
          }
        },
      });
      $("#charges tbody").on("click", ".btnEdit", function () {
        var data = table.row(this).data();
        props.chargeList &&
          props.chargeList.map((val, idx) => {
            if (val.id == this.name) {
              props.sendValues("charges_id", val.id);
              props.sendValues("chargeNo", val.charge_no);
              if (new Date(val.date_registered) != "Invalid Date") {
                props.sendValues(
                  "dateRegistered",
                  new Date(val.date_registered)
                );
              }
              if (new Date(val.date_discharge) != "Invalid Date") {
                props.sendValues("dateDischarge", new Date(val.date_discharge));
              }
              props.sendValues("currency", val.currency_id);
              props.sendValues("amountSecured", val.amount_secured);
              props.sendValues("chargee", val.chargee);
              props.sendValues("remark", val.remarks);
              props.sendValues("docLink", val.document_link);
              props.sendValues("docId", val.document_id);
              // props.sendValues("attachment", val.attachment);
              props.sendValues("attachmentKey", val.attachment_keyword);
              props.sendValues("docType", val.document_type);
              if (new Date(val.document_date) != "Invalid Date") {
                props.sendValues("docDate", new Date(val.document_date));
              }
              props.sendValues("note", val.notes);
              props.sendValues("editCharges", true);

              //   props.setFieldValues("documentUpload", val.document_upload);
              //   props.setFieldValues("documentLink", val.document_link);
              //   props.setFieldValues("personal_id", val.id);
              //   props.setFieldValues("editPersonal", true);
            }
          });
      });
      
      $("#charges tbody").on("click", ".document", function (e) {
        var targetid=e.target.id;
        props.setValue("client_key", targetid);
        console.log("target",targetid)
        props.setValue("attachmentModal", true)
        
      });

      $("#charges tbody").on("click", ".btnDel", function () {
        var data = table.row(this).data();
        console.log("data", data);
        props.sendValues("charges_id", this.name);
        props.sendValues("deleteCharges", true);
      });

      $("#charges tbody").on("click", ".btnbox", function (e) {
        var data = table.row(this).data();
        console.log("data", data);
        var allids="";
        // let arr = [...props.chargeList];
        props.chargeList && props.chargeList.map((key)=>{
            // if (key.id == e.target.title) {
            //   key.check=e.target.checked
            // }
            var checkid="check"+key.id;
            if(document.getElementById(checkid) != null){
              var checkstatus=document.getElementById(checkid).checked;
              key.check =checkstatus;
              if(checkstatus == true){
                allids += allids + key.id+",";
              }
            }
  
        });
        // arr[table.row(this).index()].check = e.target.checked;
        // props.sendValues("chargeList", arr);
        // console.log("nature", e.target.name);

        

        if(allids == ""){
          props.sendValues("charges_id","");
        }else{
          allids = allids.slice(0, -1);
          props.sendValues("charges_id",allids);
        }
        // if (e.target.name != "" && e.target.name != undefined && e.target.checked == true) {
        //   props.sendValues("charges_id", e.target.name);
        // } else {
        //   props.sendValues("charges_id", "");
        // }
      });

      // // Add event listener for opening and closing details
      // table.on("click", "td.details-control", function () {
      //   var tr = $(this).closest("tr");
      //   var row = table.row(tr);
      //   if (row.data()) {
      //     if (row.child.isShown()) {
      //       // This row is already open - close it
      //       row.child.hide();
      //       tr.removeClass("shown");
      //       $("tr.shown td.details-control").toggleClass(
      //         "tr.shown td.details-control"
      //       );
      //     } else {
      //       // Open this row
      //       row.child(format(row.data())).show();
      //       tr.addClass("shown");
      //     }
      //   }
      // });
    });
  }, [props.chargeList]);

  return (
    <Fragment>
      <div id="charges-tab" className="tab-pane fade active in">
        <h3 />
        <p></p>
        <div className="box box-primary box_align_left" boxHeight>
          <div className="box-header">
            <div>
              <nav className="navbar navbar-default" role="navigation">
                <div className="container-fluid" id="navfluid">
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle"
                      data-toggle="collapse"
                      data-target="#navigationbar"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                    <a className="navbar-brand" href="#" />
                  </div>
                  <div className="collapse navbar-collapse" id="navigationbar">
                    <ul className="nav navbar-nav">
                      <ul className="nav navbar-nav">
                        <li>
                          <a
                            href="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Charges
                            <span className="caret" />
                          </a>
                          <ul className="dropdown-menu">
                          {
                             sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(31)?(
                            <li>
                              <a
                                role="menuitem"
                                tabIndex={-1}
                                onClick={() =>
                                  props.sendValues("addCharges", true)
                                }
                              >
                                Add
                              </a>
                            </li>
                             ):null}
                             {
                             sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(34)?(
                            <li>
                              <a
                                className="button-approval"
                                href="javascript:void()"
                                data-title="Approve Selection"
                                data-table="charges"
                                data-selection="Approve"
                                onClick={() => {
                                  if (props.charges_id !== "") {
                                    props.sendValues("approve_modal", true);
                                  } else {
                                    props.setFieldValues(
                                      "nothing_select",
                                      true
                                    );
                                  }
                                }}
                              >
                                Approve
                              </a>
                            </li>
                             ):null}
                          </ul>
                        </li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </nav>
              <div className="box-tools pull-right" />
            </div>
            <div />
          </div>
          <div className="box-body" boxHeight>
            <div className="table-responsive">
              <div
                id="charges_wrapper"
                className="dataTables_wrapper form-inline dt-bootstrap no-footer"
              >
                
                      <table
                        id="charges"
                        className="table table-striped table-bordered col-md-12 dataTable no-footer"
                        role="grid"
                        style={{ marginLeft: 0 ,padding:0 }}
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Select"
                              style={{ width: "74px" }}
                            >
                              Select
                            </th>
                            <th
                              className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_asc"
                              tabIndex={0}
                              aria-controls="charges"
                              rowSpan={1}
                              colSpan={1}
                              aria-sort="ascending"
                              aria-label="Charge No: activate to sort column descending"
                              style={{ width: "73px" }}
                            >
                              Charge No
                            </th>
                            <th
                              className="col-xs-2 col-sm-1 col-md-1 col-lg-1 sorting"
                              tabIndex={0}
                              aria-controls="charges"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Reg. Date: activate to sort column ascending"
                              style={{ width: "73px" }}
                            >
                              Reg. Date
                            </th>
                            <th
                              className="col-xs-2 col-sm-1 col-md-2 col-lg-1 sorting"
                              tabIndex={0}
                              aria-controls="charges"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Dis. Date: activate to sort column ascending"
                              style={{ width: "73px" }}
                            >
                              Dis. Date
                            </th>
                            <th
                              className="hidden-xs hidden-sm hidden-md col-lg-1 sorting"
                              tabIndex={0}
                              aria-controls="charges"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Currency: activate to sort column ascending"
                              style={{ width: "73px" }}
                            >
                              Currency
                            </th>
                            <th
                              className="col-xs-2 col-sm-2 col-md-2 col-lg-1 sorting"
                              tabIndex={0}
                              aria-controls="charges"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Amt Secured: activate to sort column ascending"
                              style={{ width: "74px" }}
                            >
                              Amt Secured
                            </th>
                            <th
                              className="col-xs-2 col-sm-2 col-md-2 col-lg-2 sorting"
                              tabIndex={0}
                              aria-controls="charges"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Charges: activate to sort column ascending"
                              style={{ width: "188px" }}
                            >
                              Charges
                            </th>
                            <th
                              className="col-xs-2 col-sm-2 col-md-2 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Actions"
                              style={{ width: "75px" }}
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                   
                 
                <div id="client-reminder_processing" className="dataTables_processing panel panel-default" style={{ display: props.processModal }}>Processing...</div>
              </div>
            </div>
          </div>
          <div className="box-footer clearfix no-border"></div>
        </div>
        <p />
      </div>
      <AddCharges />
      <Nothing />
      <ApproveCharges />
      <DeleteCharges />
      <EditCharges />
      <Attachment />
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    chargeList: state.Charges.chargeList,
    charges_id: state.Charges.charges_id,
    currencyList: state.Charges.currencyList,
    processModal:state.Client.processModal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(charges(k, v)),
    getChargesList: () => dispatch(getChargesList()),
    setFieldValues: (f, v) => dispatch(personalActions.personal_data(f, v)),
    getCurrencyList: () => dispatch(getCurrencyList()),
    getDocumentList:()=> dispatch(settings.getDocumentClassification()),
    setValue: (f, v) => dispatch(client.changeClientInfo(f, v)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Charges);
