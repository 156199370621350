import React, { Fragment } from "react";
import "./LeftSideBar.css";
import history from "../stores/history";
import { Link } from "react-router-dom";
import moment from "moment";

export default function Header(props) {
  return (
    <Fragment>
      <header className="main-header">
        <a id="logoid" href="/" className="logo logo-background">
          <img className="logo-img" src="/assets/img/logo.svg" />
        </a>
        {/* Header Navbar: style can be found in header.less */}
        <nav className="navbar navbar-static-top" role="navigation">
          {/* Side bar toggle button*/}
          <a
            href="#"
            className="sidebar-toggle"
            data-toggle="offcanvas"
            role="button"
          >
            <span className="sr-only">Toggle navigation</span>
          </a>
          <div className="crm-main-title" style={{ maxWidth: "70%" }}>
            {(()=>{
              if(window.location.pathname == "/profile"){
                return <span>Profile</span>
              }else if (window.location.pathname == "/settings"){
                return <span>Settings</span>
              }else if (window.location.pathname == "/client_individual"){
                return <span>{sessionStorage.getItem("selectedClient")}</span>
              }else{
                return <span>Summary</span>
              }
            })()}
            
          </div>
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              {/* <li className="dropdown user user-menu" id="id_notification_menu">
                <a href="#">
                  <i className="fa fa-bell" />
                </a>
              </li> */}
              {/* User Account: style can be found in dropdown.less */}
              <li className="dropdown user user-menu">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  <img
                    src="/assets/img/user.png"
                    className="user-image"
                    alt="User Image"
                  />
                  <span className="hidden-xs">{sessionStorage.getItem("user_name")}</span>
                </a>
                <ul className="dropdown-menu">
                  {/* User image */}
                  <li className="user-header">
                    <img
                      src="/assets/img/user.png"
                      className="img-circle"
                      alt="User Image"
                    />
                    {sessionStorage.getItem("user_name")}
                    <p>{sessionStorage.getItem("user_role")}</p>
                    <p>
                      <small>ACCTAX</small>
                    </p>
                    <p>
                      <small>Member since {moment(sessionStorage.getItem("join_date")).format("MMM.YYYY")}</small>
                    </p>
                  </li>
                  {/* Menu Body */}
                  <li className="user-body">
                    <a href="/profile" className="user-details">
                      Profile
                    </a>
                    <a href="/settings" className="user-details">
                      Admin Setting
                    </a>
                    <ul className="dropdown-menu">
                      {/* User image */}
                      <li className="user-header">
                        <img
                          src="/assets/img/user.png"
                          className="img-circle"
                          alt="User Image"
                        />
                        SMITIV
                        <p>Web Developer</p>
                        <p>
                          <small>Gerard Business Services Consultancy</small>
                        </p>
                        <p>
                          <small>Member since Nov. 2012</small>
                        </p>
                      </li>
                      {/* Menu Body */}
                      <li className="user-body">
                        <Link to="/profile">
                          <a className="user-details">Profile</a>
                        </Link>
                        <Link to="/settings">
                          <a className="user-details">Admin Setting</a>
                        </Link>
                      </li>
                      {/* Menu Footer*/}
                      <li className="user-footer">
                        <a href="/auth/logout">Sign out</a>
                      </li>
                    </ul>
                  </li>
                  {/* Menu Footer*/}
                  <li className="user-footer">
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        window.sessionStorage.clear();
                        window.localStorage.removeItem("token");
                        history.push("/");
                      }}
                    >
                      Sign out
                    </a>
                  </li>
                </ul>
              </li>
              {/* <li>
                <a href="#" onclick="showhelp('/about')">
                  <i className="glyphicon glyphicon-question-sign" />
                </a>
              </li> */}
            </ul>
          </div>
        </nav>
      </header>
    </Fragment>
  );
}
