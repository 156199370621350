/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import DocumentEditor from "../../documentEditor/DocumentEditor";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Container, Modal } from "react-bootstrap";
import "file-viewer";
import * as share from "../../../actions/clientDetails/ShareRegister";
import * as actions from "../../../actions/clientDetails/Document";
import { changeClientInfo } from "../../../actions/Client";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DecoupledcEditor from "@ckeditor/ckeditor5-build-decoupled-document";
// import "../../../../node_modules/@ckeditor/ckeditor5-build-decoupled-document/build/ckeditor.js"

var _ = require("lodash");

function TransferShares(props) {
  const iframeRef = useRef(0);
  const [random, setRandom] = useState(0);
  const [url, setUrl] = useState("");
  const [url2, setUrl2] = useState("");
  const [obj, setObj] = useState({});
  const [signObj, setSignObj] = useState({});
  const [receivers, setReceivers] = useState([]);
  const [transferor, setTransferor] = useState({});
  const [shareHolder, setShareHolder] = useState([{ shareholder_name: "" }]);
  const [templateData, setTemplateData] = useState();
  const [rendr, setRendr] = useState(false);
  const [temName, setTemName] = useState("");
  const [shareHolderList, setShareHolderList] = useState([]);
  const [directorList, setDirectorList] = useState([]);
  const [secratoryList, setSecratoryList] = useState([]);

  // const frame = document.getElementById("myFrame");
  const [editor, setEditor] = useState("");
  const [edited, setEdited] = useState("");
  const [isCreateTemplate, setIsCreateTemplate] = useState(false);

  console.log("shareHolderList", shareHolderList);

  useEffect(() => {
    props.getShareHolderList();
    setRandom(random + 1000);
    setUrl(
      `https://docs.google.com/viewer?url=https://api.acctax.tech${props.docPreview}&embedded=true`
    );
    iframeRef.current = random;
  }, []);

  const assignDefaultFieldValues = () => {
    if (props.editDocumentModal == true) {
      props.documentList &&
        props.documentList.map((data) => {
          // setObj({});
          data.list.map((lis) => {
            if (lis.id == props.document_id) {
              let arr = lis.placeholder_values.split(",");
              let signArr = lis.signature_placeholder_values?.split(",");
              if (props.defaultFields) {
                if (
                  props.defaultFields.placeholderList &&
                  props.defaultFields.placeholderList.length > 0
                ) {
                  props.defaultFields.placeholderList.map((fie, ind) => {
                    arr.map((val, idx) => {
                      if (ind == idx) {
                        // let ass = {...obj}
                        obj[fie.Name] = val;
                        console.log("objec", obj);
                        setObj((prevObj) => {
                          return { ...prevObj, ...obj };
                        });
                      }
                    });
                  });
                }
                if (signArr == undefined) {
                  signArr = [];
                }
                if (
                  props.defaultFields.signaturePlaceholderList &&
                  props.defaultFields.signaturePlaceholderList.length > 0 &&
                  signArr.length > 0
                ) {
                  props.defaultFields.signaturePlaceholderList.map(
                    (fie, ind) => {
                      signArr.map((val, idx) => {
                        if (ind == idx) {
                          // let ass = {...obj}
                          signObj[fie.Name] = val;
                          console.log("objec", signObj);
                          setSignObj((prevObj) => {
                            return { ...prevObj, ...signObj };
                          });
                        }
                      });
                    }
                  );
                }
              }
            }
          });
        });
    }
  };

  useEffect(() => {
    var company;
    if (props.defaultFields) {
      if (
        props.defaultFields.placeholderList &&
        props.defaultFields.placeholderList.length > 0
      ) {
        props.defaultFields.placeholderList.map((key) => {
          obj[key.Name] = "";
          setObj({ ...obj });
        });
      }
      if (
        props.defaultFields.placeholderList &&
        props.defaultFields.signaturePlaceholderList.length > 0
      ) {
        props.defaultFields.signaturePlaceholderList.map((key) => {
          signObj[key.Name] = "";
          setSignObj({ ...signObj });
        });
      }
      console.log(
        " props.defaultFields.clientdata",
        props.defaultFields.clientdata
      );
      if (
        props.defaultFields.clientdata &&
        props.defaultFields.clientdata.length > 0
      ) {
        sessionStorage.setItem(
          "companyname",
          props.defaultFields.clientdata[0].company_name
        );
        sessionStorage.setItem(
          "registration_no",
          props.defaultFields.clientdata[0].registration_no
        );
        sessionStorage.setItem("current_date", moment().format("DD/MM/YYYY"));
        sessionStorage.setItem(
          "address",
          props.defaultFields.clientdata[0].address
        );
        changeFunc(sessionStorage.getItem("companyname"), "company_name");
        changeFunc(
          sessionStorage.getItem("registration_no"),
          "registration_no"
        );
        changeFunc(sessionStorage.getItem("address"), "address");
        changeFunc(sessionStorage.getItem("current_date"), "current_date");
        if (sessionStorage.getItem("director_name1") != null) {
          changeFunc(
            sessionStorage.getItem("director_name1"),
            "director_name1"
          );
        }
        if (sessionStorage.getItem("director_name2") != null) {
          changeFunc(
            sessionStorage.getItem("director_name2"),
            "director_name2"
          );
        }
        if (sessionStorage.getItem("director_name3") != null) {
          changeFunc(
            sessionStorage.getItem("director_name3"),
            "director_name3"
          );
        }
        if (sessionStorage.getItem("director_name4") != null) {
          changeFunc(
            sessionStorage.getItem("director_name4"),
            "director_name4"
          );
        }
        if (sessionStorage.getItem("shareholder_name1") != null) {
          changeFunc(
            sessionStorage.getItem("shareholder_name1"),
            "shareholder_name1"
          );
        }
        if (sessionStorage.getItem("shareholder_name2") != null) {
          changeFunc(
            sessionStorage.getItem("shareholder_name2"),
            "shareholder_name2"
          );
        }
        if (sessionStorage.getItem("shareholder_name3") != null) {
          changeFunc(
            sessionStorage.getItem("shareholder_name3"),
            "shareholder_name3"
          );
        }
        if (sessionStorage.getItem("shareholder_name4") != null) {
          changeFunc(
            sessionStorage.getItem("shareholder_name4"),
            "shareholder_name4"
          );
        }

        if (sessionStorage.getItem("secratory_name1") != null) {
          changeFunc(
            sessionStorage.getItem("secratory_name1"),
            "secratory_name1"
          );
        }

        if (sessionStorage.getItem("secratory_name2") != null) {
          changeFunc(
            sessionStorage.getItem("secratory_name2"),
            "secratory_name2"
          );
        }

        if (sessionStorage.getItem("secratory_name3") != null) {
          changeFunc(
            sessionStorage.getItem("secratory_name3"),
            "secratory_name3"
          );
        }

        if (sessionStorage.getItem("secratory_name4") != null) {
          changeFunc(
            sessionStorage.getItem("secratory_name4"),
            "secratory_name4"
          );
        }
      }
      if (
        props.defaultFields.officebearerdata &&
        props.defaultFields.officebearerdata.length > 0
      ) {
        setDirectorList(props.defaultFields.officebearerdata);
        props.defaultFields.officebearerdata.map((key, inx) => {
          var jinx = inx + 1;
          var directorfield = "director_name" + jinx;
          sessionStorage.setItem(directorfield, key.name);
        });
      }
      if (
        props.defaultFields.officebearerdata_shareholder &&
        props.defaultFields.officebearerdata_shareholder.length > 0
      ) {
        console.log(
          "officebearerdata_shareholder",
          props.defaultFields.officebearerdata_shareholder
        );
        setShareHolderList(props.defaultFields.officebearerdata_shareholder);
        props.defaultFields.officebearerdata_shareholder.map((key, inx) => {
          var jinx = inx + 1;
          var directorfield = "shareholder_name" + jinx;
          sessionStorage.setItem(directorfield, key.name);
        });
      }
      if (
        props.defaultFields.officebearerdata_secratory &&
        props.defaultFields.officebearerdata_secratory.length > 0
      ) {
        setSecratoryList(props.defaultFields.officebearerdata_secratory)
        props.defaultFields.officebearerdata_secratory.map((key, inx) => {
          var jinx = inx + 1;
          var directorfield = "secratory_name" + jinx;
          sessionStorage.setItem(directorfield, key.name);
        });
      }
    }

    if (props.editDocumentModal == true) {
      assignDefaultFieldValues();
    }
  }, [props.defaultFields]);

  const docs = [
    { uri: "share_transfer_sameer_to_bala.docx" }, // Local File
  ];

  const changeFunc = (e, val) => {
    let placeholder_list = {};
    console.log("change-->", e, val);
    if (val != undefined && val != null) {
      obj[val] = e;
      setObj({ ...obj });
    }
  };

  const changeSignFunc = (e, val) => {
    let placeholder_list = {};
    if (val != undefined && val != null) {
      signObj[val] = e;
      setSignObj({ ...signObj });
    }
  };

  const saveChangesFunc = (e) => {
    e.preventDefault();
    console.log("object", obj);
    let input = {
      document_type: props.selectDocumentType,
      transferor_list: transferor,
      receiver_list: receivers,
      shareholdername_list: shareHolder,
      user_id: sessionStorage.getItem("login_userid"),
      client_id: sessionStorage.getItem("personalId"),
      status: 1,
      htmldata: edited,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
      selectedid: props.selectedid,
    };

    let editInput = {
      document_id: props.document_id,
      document_type: props.selectDocumentType,
      transferor_list: transferor,
      receiver_list: receivers,
      shareholdername_list: shareHolder,
      attachment_keyword: "",
      notes: "",
      user_id: sessionStorage.getItem("login_userid"),
      client_id: sessionStorage.getItem("personalId"),
      status: 1,
      htmldata: edited,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
      selectedid: props.selectedid,
    };
    if (props.editDocumentModal == true) {
      console.log("edit pa", editInput);
      props.editDocument(editInput, "draft", props.tabSelected);
    } else {
      console.log("insert pa", input);
      props.sendAndSaveDocument(
        input,
        "draft",
        props.tabSelected,
        templateData,
        temName
      );
    }
  };
  console.log(props.selectedid, "selectedid EGM");
  const previewFunc = (e) => {
    // e.preventDefault();

    let input = {
      document_type: props.selectDocumentType,
      generate_doc: true,
      file_url: props.docPreview,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
    };

    props.getDocumentPreview(input, setRendr);
  };
  useEffect(() => {
    if(props.selectDocumentType !== ""){
      props.sendDocTypeAndGetDocument(props.selectDocumentType, setRendr);
      setUrl2("");
    }
    
    //previewFunc2();
  }, [obj]);
  useEffect(() => {
    if (url2 == "" && props.selectDocumentType !== "") {
      console.log("arrayselectDocumentType", props.to);
      previewFunc2();
      setUrl2("URLUpdated");
    }
  }, [props.docPreview]);
  useEffect(() => {
    if (url2 == "" && props.selectDocumentType !== "") {
      previewFunc2();
      setUrl2("URLUpdated");
    }
  }, [signObj]);
  const previewFunc2 = () => {
    let input = {
      document_type: props.selectDocumentType,
      generate_doc: true,
      file_url: props.docPreview,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
    };
    props.getDocumentPreview(input, setRendr);
  };
  const sendNewFuncMultipledocument = () => {
    setTimeout(() => {
      let ass = Object.values(obj);
      let allids = props.to;
      allids.forEach((element) => {
        let id = element.value;
        let check = ass.find((ele) => {
          return ele === "";
        });
        console.log("transerro", transferor);

        let check2 = false;
        if (signObj) {
          let ass1 = Object.values(signObj);
          let findErr = ass1.find((ele) => {
            return ele === "";
          });
          check2 = findErr ? false : true;
        } else {
          check2 = true;
        }
        console.log("check", check, "check2", check2);
        // if (check == undefined) {
        let input = {
          document_type: id,
          user_id: sessionStorage.getItem("login_userid"),
          client_id: sessionStorage.getItem("personalId"),
          status: 5,
          placeholder_list: obj,
          sign_placeholder_list: signObj,
          transferor_list: transferor,
          receiver_list: receivers,
          shareholdername_list: shareHolder,
          selectedid: props.selectedid,
        };
        props.sendAndSaveDocument_multiple(input, "save", props.tabSelected);
      });
    }, 1000);
  };
  const sendNewFunc = (e) => {
    e.preventDefault();

    let ass = Object.values(obj);
    console.log("obj", obj);
    let check = ass.find((ele) => {
      return ele === "";
    });
    console.log("transerro", transferor);

    let check2 = false;
    if (signObj) {
      let ass1 = Object.values(signObj);
      let findErr = ass1.find((ele) => {
        return ele === "";
      });
      check2 = findErr ? false : true;
    } else {
      check2 = true;
    }
    console.log("check", check, "check2", check2);
    // if (check == undefined) {
    let input = {
      document_type: props.selectDocumentType,
      user_id: sessionStorage.getItem("login_userid"),
      client_id: sessionStorage.getItem("personalId"),
      status: 5,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
      transferor_list: transferor,
      receiver_list: receivers,
      shareholdername_list: shareHolder,
      selectedid: props.selectedid,
      isCreateTemplate: isCreateTemplate,
    };
    props.sendAndSaveDocument(
      input,
      "save",
      props.tabSelected,
      templateData,
      temName
    );
    // props.sendValues("sendRequestModal", true)
    // } else {
    //   props.changeClientInfo("clientApiStatus", 2);
    //   props.changeClientInfo("message", "Please Fill All Required Field");
    //   setTimeout(() => {
    //     props.changeClientInfo("clientApiStatus", 0);
    //     props.changeClientInfo("message", "");
    //   }, 2500);
    // }
  };
  const nextFunction = (e) => {
    e.preventDefault();
    let allids = props.to;
    let currentindex = props.nextValue - 1;
    let newIndex = currentindex + 1;
    let increaseIndex = currentindex + 2;
    let documentid = allids[currentindex].value;
    let ass = Object.values(obj);
    console.log("obj", obj);
    let check = ass.find((ele) => {
      return ele === "";
    });
    console.log("transerro", transferor);

    let check2 = false;
    if (signObj) {
      let ass1 = Object.values(signObj);
      let findErr = ass1.find((ele) => {
        return ele === "";
      });
      check2 = findErr ? false : true;
    } else {
      check2 = true;
    }
    console.log("check", check, "check2", check2);
    // if (check == undefined) {
    let input = {
      document_type: documentid,
      user_id: sessionStorage.getItem("login_userid"),
      client_id: sessionStorage.getItem("personalId"),
      status: 5,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
      transferor_list: transferor,
      receiver_list: receivers,
      shareholdername_list: shareHolder,
      selectedid: props.selectedid,
    };
    props.sendValues("nextValue", increaseIndex);
    props.sendAndSaveDocumentNext(
      input,
      "save",
      props.tabSelected,
      templateData,
      temName
    );
    if (props.nextValue < props.to.length) {
      props.getDefaultPlaceHolder(allids[newIndex].value);
      props.sendValues("selectDocumentType", allids[newIndex].value);
      previewFunc2();
    }
  };

  const receiversAddFunc = () => {
    let arr = [...receivers];
    let add = { receiver_name: "", no_of_shares_transfered: "" };
    arr.push(add);
    setReceivers([...arr]);
  };

  const removeReceiverFunc = (ind) => {
    let arr = [...receivers];
    if (arr.length > 1) {
      arr.splice(ind, 1);
      setReceivers([...arr]);
    }
  };

  const addReceiverDetails = (e, idx) => {
    let arr = [...receivers];
    arr[idx][e.target.name] = e.target.value;
    setReceivers([...arr]);
  };

  const addTransferorDetails = (e) => {
    let obj = { ...transferor };
    obj[e.target.name] = e.target.value;
    setTransferor({ ...obj });
  };

  const addShareHolder = () => {
    let arr = [...shareHolder];
    let add = { shareholder_name: "" };
    arr.push(add);
    setShareHolder([...arr]);
  };

  const addShareHolderDetails = (e, idx) => {
    let arr = [...shareHolder];
    arr[idx][e.target.name] = e.target.value;
    setShareHolder([...arr]);
  };

  const removeShareHoderFunc = (idx) => {
    let arr = [...shareHolder];
    if (arr.length > 1) {
      arr.splice(idx, 1);
      setShareHolder([...arr]);
    }
  };

  return (
    <Modal
      show={props.transferOfShareModal}
      id="myModal"
      className="modal fade in"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-dialog modal-xl"
      style={{ display: "block" }}
    >
      <div className="">
        <div className="modal-content ">
          <div className="modal-header ">
            {/* <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> */}
            <h4 className="modal-title blue-text">EGM - Transfer of Shares</h4>
          </div>
          <div className="modal-body pb-0">
            <div className="row">
              <div className="col-sm-6 border-right-gray">
                {props.defaultFields &&
                  props.defaultFields.placeholderList?.map((fie, idx) => {
                    let companynamevalue = "";

                    if (fie.Name == "company_name") {
                      companynamevalue = sessionStorage.getItem("companyname");
                    }
                    if (fie.Name == "address") {
                      companynamevalue = sessionStorage.getItem("address");
                    }
                    if (fie.Name == "current_date") {
                      companynamevalue = sessionStorage.getItem("current_date");
                    }
                    if (fie.Name == "registration_no") {
                      companynamevalue =
                        sessionStorage.getItem("registration_no");
                    }
                    if (fie.Name == "director_name1") {
                      companynamevalue =
                        sessionStorage.getItem("director_name1");
                    }
                    if (fie.Name == "director_name2") {
                      companynamevalue =
                        sessionStorage.getItem("director_name2");
                    }
                    if (fie.Name == "director_name3") {
                      companynamevalue =
                        sessionStorage.getItem("director_name3");
                    }
                    if (fie.Name == "director_name4") {
                      companynamevalue =
                        sessionStorage.getItem("director_name4");
                    }
                    if (fie.Name == "shareholder_name1") {
                      companynamevalue =
                        sessionStorage.getItem("shareholder_name1");
                    }
                    if (fie.Name == "shareholder_name2") {
                      companynamevalue =
                        sessionStorage.getItem("shareholder_name2");
                    }
                    if (fie.Name == "shareholder_name3") {
                      companynamevalue =
                        sessionStorage.getItem("shareholder_name3");
                    }
                    if (fie.Name == "shareholder_name4") {
                      companynamevalue =
                        sessionStorage.getItem("shareholder_name4");
                    }
                    if (fie.Name == "secratory_name1") {
                      companynamevalue =
                        sessionStorage.getItem("secratory_name1");
                    }
                    if (fie.Name == "secratory_name2") {
                      companynamevalue =
                        sessionStorage.getItem("secratory_name2");
                    }
                    if (fie.Name == "secratory_name3") {
                      companynamevalue =
                        sessionStorage.getItem("secratory_name3");
                    }
                    if (fie.Name == "secratory_name4") {
                      companynamevalue =
                        sessionStorage.getItem("secratory_name4");
                    }

                    return fie.Name.includes("director_name") ? (
                      <div className="form-group" key={idx}>
                        <label
                          htmlFor="companyname"
                          className="control-label col-sm-4 required"
                        >
                          {_.startCase(fie.Name)}
                          <p className="help-block">(* Required) </p>
                        </label>
                        <div className="col-sm-8">
                          <select
                            className="form-control"
                            onChange={(e) =>
                              changeFunc(e.target.value, fie.Name)
                            }
                            required="required"
                            name="companyname"
                            id="companyname"
                            defaultValue={companynamevalue}
                          >
                            {directorList.map((data, i) => {
                              return (
                                <option value={data?.name} selected="selected">
                                  {data?.name}
                                </option>
                              );
                            })}
                          </select>
                          <p className="help-block">Pre populated</p>
                        </div>
                      </div>
                    ) : fie.Name.includes("shareholder_name") ? (
                      <div className="form-group" key={idx}>
                        <label
                          htmlFor="companyname"
                          className="control-label col-sm-4 required"
                        >
                          {_.startCase(fie.Name)}
                          <p className="help-block">(* Required) </p>
                        </label>
                        <div className="col-sm-8">
                          <select
                            className="form-control"
                            onChange={(e) =>
                              changeFunc(e.target.value, fie.Name)
                            }
                            required="required"
                            name="companyname"
                            id="companyname"
                            defaultValue={companynamevalue}
                          >
                            {shareHolderList.map((data, i) => {
                              return (
                                <option value={data?.name} selected="selected">
                                  {data?.name}
                                </option>
                              );
                            })}
                          </select>
                          <p className="help-block">Pre populated</p>
                        </div>
                      </div>
                    ) : fie.Name.includes("secratory_name") ? <div className="form-group" key={idx}>
                    <label
                      htmlFor="companyname"
                      className="control-label col-sm-4 required"
                    >
                      {_.startCase(fie.Name)}
                      <p className="help-block">(* Required) </p>
                    </label>
                    <div className="col-sm-8">
                      <select
                        className="form-control"
                        onChange={(e) =>
                          changeFunc(e.target.value, fie.Name)
                        }
                        required="required"
                        name="companyname"
                        id="companyname"
                        defaultValue={companynamevalue}
                      >
                        {secratoryList.map((data, i) => {
                          return (
                            <option value={data?.name} selected="selected">
                              {data?.name}
                            </option>
                          );
                        })}
                      </select>
                      <p className="help-block">Pre populated</p>
                    </div>
                  </div> : (
                      <div className="form-group" key={idx}>
                        <label
                          htmlFor="companyname"
                          className="control-label col-sm-4 required"
                        >
                          {_.startCase(fie.Name)}
                          <p className="help-block">(* Required) </p>
                        </label>
                        <div className="col-sm-8">
                          <input
                            className="form-control"
                            required="required"
                            name="companyname"
                            type="text"
                            // value={obj[fie.Name]}
                            id="companyname"
                            onChange={(e) =>
                              changeFunc(e.target.value, fie.Name)
                            }
                            defaultValue={companynamevalue}
                          />
                          <p className="help-block">Pre populated</p>
                        </div>
                      </div>
                    );
                  })}

                <div className="col-sm-12 text-center">
                  <button
                    className="btn blue-border-btn mb-10"
                    onClick={() => previewFunc()}
                  >
                    Get Document Preview
                  </button>
                </div>
                <div className="form-group">
                  <div className="col-sm-1" />
                  <div className="col-sm-10">
                    <div className="vertical-border" />
                  </div>
                  <div className="col-sm-1" />
                </div>

                <div className="col-sm-12 text-center">
                  <button
                    className="btn black-border-btn mb-30"
                    onClick={() => saveChangesFunc}
                  >
                    {props.editDocumentModal == true
                      ? "Update changes"
                      : "Save changes"}
                  </button>
                </div>
                {/* <div>
                  <div className="form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-10 align-center">
                      <select className="form-control" id="Name" name="Name">
                        <option value selected="selected">
                          Form Name
                        </option>
                      </select>
                      <button className="btn blue-btn ml-10">
                        Save template
                      </button>
                    </div>
                    <div className="col-sm-1" />
                  </div>
                </div> */}
              </div>
              <div className="col-sm-6 ">
                <div
                  className="mt-20 text-center"
                  style={{ minHeight: "728px" }}
                >
                  <h4 className="mb-25">Form Preview</h4>
                  {props?.fileJson && (
                    <DocumentEditor
                      fileJson={props?.fileJson}
                      setTemplateData={setTemplateData}
                      rendr={rendr}
                      setRendr={setRendr}
                    />
                  )}

                  {/* {props.docPreview && (
                    <>
                      <iframe
                        key={random}
                        src={`https://docs.google.com/viewer?url=https://api.acctax.tech${props.docPreview}&embedded=true`}
                        frameBorder="0"
                        width="100%"
                        id="iFrame"
                        title="doc-preview"
                        style={{ minHeight: "inherit" }}
                      />
                    </>
                  )} */}
                  <div className="form-group">
                    <div
                      className="col-sm-5 align-center justify-center"
                      style={{ justifyContent: "center", marginTop: "25px" }}
                    >
                      <input
                        type="checkbox"
                        className="check-box-active"
                        id="isCreateTemplate"
                        name="create_template"
                        value={isCreateTemplate}
                        onClick={(e) => {
                          setIsCreateTemplate(e.target.checked);
                        }}
                      />
                      <label
                        htmlFor="isCreateTemplate"
                        className="control-label pl-10 mb-0 required"
                      >
                        Create Template
                      </label>
                    </div>
                  </div>
                  {isCreateTemplate && (
                    <div className="form-group">
                      <div
                        className="col-sm-10 align-center justify-center"
                        style={{
                          justifyContent: "center",
                          marginTop: "5px",
                          marginLeft: "13px",
                        }}
                      >
                        <label
                          htmlFor="isCreateTemplate"
                          className="control-label col-sm-6 pl-12 mb-0 required"
                        >
                          Template Name
                        </label>
                        <input
                          type="text"
                          className="form-control col-sm-4"
                          name="templateName"
                          onChange={(e) => setTemName(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-12">
                {props.clientApiStatus ? (
                  <div
                    className={`alert ${
                      props.clientApiStatus === 1 ? "" : "alert-danger"
                    }`}
                    role="alert"
                  >
                    {props.clientApiStatus === 1 ? "" : props.message}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                className="col-sm-12 pt-20 pb-20 mt-20 flex-center-space"
                style={{ background: "rgba(92, 132, 149, 0.32)" }}
              >
                <button
                  className="btn transparent-btn"
                  onClick={() => {
                    props.sendValues("transferOfShareModal", false);
                    props.sendValues("defaultFields", []);
                    props.sendValues("multiple_document_id", []);
                    props.sendValues("editDocumentModal", false);
                    props.sendValues("document_id", "");
                    props.sendValues("selectDocumentType", "");
                    setObj({});
                  }}
                >
                  Cancel
                </button>
                {props.lengtharray == props.nextValue && (
                  <button className="btn blue-btn" onClick={sendNewFunc}>
                    Goto Next
                  </button>
                )}
                {props.lengtharray != props.nextValue && (
                  <button className="btn blue-btn" onClick={nextFunction}>
                    Next Document
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

// props.sendValues("sendRequestModal", true)
const mapStateToProps = (state, ownProps) => {
  const {
    transferOfShareModal,
    docPreview,
    defaultFields,
    selectDocumentType,
    to,
    multiple_document_id,
    lengtharray,
    nextValue,
    tabSelected,
    editDocumentModal,
    documentList,
    document_id,
    htmldata,
    fileJson,
  } = state.Document;

  return {
    transferOfShareModal,
    docPreview,
    htmldata,
    defaultFields,
    selectDocumentType,
    to,
    multiple_document_id,
    lengtharray,
    nextValue,
    shareHolderList: state.ShareReg.shareHolderList,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    tabSelected,
    editDocumentModal,
    documentList,
    document_id,
    selectedid: state.Client.selectedid,
    fileJson,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.documentFunction(k, v)),
    getDocumentPreview: (val, setRendr) =>
      dispatch(actions.getDocumentPreview(val, setRendr)),
    sendAndSaveDocument: (val, typ, tab, templateData, temName) =>
      dispatch(
        actions.sendAndSaveDocument(val, typ, tab, templateData, temName)
      ),
    sendAndSaveDocumentNext: (val, typ, tab, templateData, temName) =>
      dispatch(
        actions.sendAndSaveDocumentNext(val, typ, tab, templateData, temName)
      ),
    getDefaultPlaceHolder: (type) =>
      dispatch(actions.getDefaultPlaceHolderNext(type)),
    sendAndSaveDocument_multiple: (val, typ, tab) =>
      dispatch(actions.sendAndSaveDocument_multiple(val, typ, tab)),
    sendDocTypeAndGetDocument: (type, setRendr) =>
      dispatch(actions.sendDocTypeAndGetDocument_AutoPreview(type, setRendr)),
    editDocument: (val, typ, tab) =>
      dispatch(actions.editDocument(val, typ, tab)),
    getShareHolderList: () => dispatch(share.getShareHolderList()),
    changeClientInfo: (k, v) => dispatch(changeClientInfo(k, v)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferShares);
