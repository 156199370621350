import config from "../common/Api_Links";
import { headers } from "../common/Headers";
import { ifTokenExpire } from "../common/TokenValidation";
import history from "../../stores/history";
import { changeClientInfo } from "../Client";
import { s3_file_upload } from "../common/S3_Upload";

export const deadline = (field, value) => {
  return {
    type: "DEADLINE_DATA",
    field,
    value,
  };
};

export const addDeadline = (data) => {
  var header = headers();
  return async (dispatch) => {
    var response = {
      status: 1,
    };
    if (data.attachment != "") {
      response = await s3_file_upload(data);
    }
    console.log("persona", response);
    if (response.status === 1) {
      data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.add_deadline, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              dispatch(deadline("addDeadlineModal", false));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                if (window.location.pathname == "/deadline_summary") {
                  dispatch(getDeadlineList());
                  dispatch(deadline("docLink", ""));
                } else {
                  dispatch(
                    getDeadlineList(sessionStorage.getItem("personalId"))
                  );
                  dispatch(deadline("docLink", ""));
                  dispatch(deadline("cc", []));
                  dispatch(deadline("to", []));
                }
              }, 2500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    } else {
      dispatch(changeClientInfo("clientApiStatus", 2));
      dispatch(changeClientInfo("message", response.message));
      setTimeout(() => {
        dispatch(changeClientInfo("clientApiStatus", 0));
        dispatch(changeClientInfo("message", ""));
      }, 2500);
    }
  };
};

export const editDeadline = (data) => {
  var header = headers();
  return async (dispatch) => {
    var response = {
      status: 1,
    };
    if (data.attachment != "") {
      response = await s3_file_upload(data);
    }
    // console.log("persona", response);
    if (response.status === 1) {
      data.attachment = response.original_link ? response.original_link : "";
      return fetch(config.edit_deadline, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              dispatch(deadline("editDeadlineModal", false));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(changeClientInfo("clientApiStatus", 1));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                if (window.location.pathname == "/deadline_summary") {
                  dispatch(getDeadlineList());
                  dispatch(deadline("cc", []));
                  dispatch(deadline("to", []));
                  dispatch(deadline("deadline_id", ''));
                } else {
                  dispatch(
                    getDeadlineList(sessionStorage.getItem("personalId"))
                  );
                  dispatch(deadline("cc", []));
                  dispatch(deadline("to", []));
                }
              }, 2500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    } else {
      dispatch(changeClientInfo("clientApiStatus", 2));
      dispatch(changeClientInfo("message", response.message));
      setTimeout(() => {
        dispatch(changeClientInfo("clientApiStatus", 0));
        dispatch(changeClientInfo("message", ""));
      }, 2500);
    }
  };
};

export const addNewTask = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.add_newTask, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(deadline("newTask", false));
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 1));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              if (window.location.pathname == "/deadline_summary") {
                dispatch(getDeadlineList());
                dispatch(deadline("docLink", ""));
                dispatch(deadline("deadline_id", ''));
              } else {
                dispatch(getDeadlineList(sessionStorage.getItem("personalId")));
                dispatch(deadline("deadline_id", ''));
              }
            }, 2500);
          } else {
            dispatch(changeClientInfo("clientApiStatus", 2));
            dispatch(changeClientInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
            }, 2500);
          }
        }
      });
  };
};

export const editNewTask = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.edit_newTask, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(deadline("editTask", false));
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 1));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              if (window.location.pathname == "/deadline_summary") {
                dispatch(getDeadlineList());
                dispatch(deadline("docLink", ""));
              } else {
                dispatch(getDeadlineList(sessionStorage.getItem("personalId")));
              }
            }, 2500);
          } else {
            dispatch(changeClientInfo("clientApiStatus", 2));
            dispatch(changeClientInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
            }, 2500);
          }
        }
      });
  };
};

export const getDeadlineDrop = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.deadline_Drop, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(deadline("clientdeadline", responseData.data));
          } else {
            dispatch(deadline("clientdeadline", []));
          }
        }
      });
  };
};

export const getReminderType = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.settings_email_template_list, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(deadline("remTypeList", responseData.data));
          } else {
            dispatch(deadline("remTypeList", []));
          }
        }
      });
  };
};

export const getDeadlineList = (k = null) => {
  
  var header = headers();
  let data;
  if (k !== undefined && typeof k !== "object") {
    data = { client_id: k };
  }
  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    let url = `${config.get_deadline_list}`;
    if(k && k.f)
      url = `${config.get_deadline_list}?${k.f}=${k.v}`;
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(deadline("deadlineList", responseData.data));
            dispatch(changeClientInfo("processModal", "none"));
          } else {
            dispatch(deadline("deadlineList", []));
          }
        }
      });
  };
};

export const getCompletedDeadlineList = () => {
  var header = headers();
  let data = {
    client_id: sessionStorage.getItem("personalId"),
  };

  return (dispatch) => {
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(config.get_completedDeadline_list, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(changeClientInfo("processModal", "none"));
            dispatch(deadline("completedDeadline", responseData.data));
          } else {
            dispatch(deadline("completedDeadline", []));
          }
        }
      });
  };
};

export const approveDeadline = (val) => {
  var header = headers();
  let value = { deadline_id: val };

  return (dispatch) => {
    return fetch(config.approve_dealine, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData.status === 1) {
          ifTokenExpire(responseData);
          dispatch(deadline("approveModal", false));
          dispatch(changeClientInfo("message", responseData.message));
          dispatch(changeClientInfo("clientApiStatus", 1));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            if (window.location.pathname == "/deadline_summary") {
              dispatch(getDeadlineList());
              dispatch(deadline("deadline_id", ''));
            } else {
              dispatch(getDeadlineList(sessionStorage.getItem("personalId")));
              dispatch(deadline("deadline_id", ''));
            }
          }, 2500);
        } else {
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", responseData.message));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};

export const deleteDeadline = (val) => {
  var header = headers();
  let value = { deadline_id: val };

  return (dispatch) => {
    return fetch(config.delete_dealine, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData.status === 1) {
          ifTokenExpire(responseData);
          dispatch(deadline("deleteModal", false));
          dispatch(changeClientInfo("message", responseData.message));
          dispatch(changeClientInfo("clientApiStatus", 1));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            if (window.location.pathname == "/deadline_summary") {
              // dispatch(getDeadlineList());
              window.location.reload();
            } else {
              dispatch(getDeadlineList(sessionStorage.getItem("personalId")));
              dispatch(deadline("deadline_id", ''));
            }
          }, 2500);
        } else {
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", responseData.message));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};

export const deleteTask = (val) => {
  var header = headers();
  let value = { task_id: val };

  return (dispatch) => {
    return fetch(config.delete_newTask, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData.status === 1) {
          ifTokenExpire(responseData);
          dispatch(deadline("deleteModal", false));
          dispatch(changeClientInfo("message", responseData.message));
          dispatch(changeClientInfo("clientApiStatus", 1));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            if (window.location.pathname == "/deadline_summary") {
              window.location.reload()
              // dispatch(getDeadlineList());
              // dispatch(deadline("taskDelete", false));
            } else {
              dispatch(getDeadlineList(sessionStorage.getItem("personalId")));
            }
          }, 2500);
        } else {
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", responseData.message));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};

export const exportByCompany = (ids) => {
  var header = headers();
  // let value = { deadline_id: val };

  return (dispatch) => {
    return fetch(config.exportByCompany, {
      method: "POST",
      body: JSON.stringify(ids),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.blob();
        }
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "filename.xlsx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
      });
  };
};
