const init = {
  addShares: false,
  editShares:false,
  splitShares: false,
  transferShares: false,
  reduceShares: false,
  convertShares: false,
  viewHistory: false,
  deleteShareModal:false,
  approveShareModal:false,
  ShareTypeList: [],
  shareHolderList: [],
  valuePerShareList: [],
  DocumentTypeList: [],
  shareRegisterList: [],
  toShareHolderList: [],
  toShareTypeList: [],
  shareHistoryList: [],
  resolutionDate: "",
  shareHolder: "",
  shareType: "",
  valuePerShare: "",
  noOfSharesHeld: "",
  remark: "",
  documentLink: "",
  documentId: "",
  attachMent: "",
  attachmentKeyword: "",
  documentType: "",
  documentDate: "",
  notes: "",
  splitResolutionDate: "",
  splitShareType: "",
  splitValues: "",
  splitTotalShare: "",
  splitTotalValue: "",
  splitRemark: "",
  splitDocLink: "",
  splitDocId: "",
  splitAttachment: "",
  splitAttachmentkey: "",
  splitDocType: "",
  splitDocResolutionDate: "",
  splitNotes: "",
  transferResolutionDate: "",
  transferShareHolder: "",
  transferToShareHolder: "",
  transferShareType: "",
  transferValueShare: "",
  transferNoOfShare: "",
  transferRemark: "",
  transferDocLink: "",
  transferDocId: "",
  transferAttachment: "",
  transferAttachmentKey: "",
  transferDocType: "",
  transferDocDate: "",
  transferNotes: "",
  reduceResolutionDate: "",
  reduceShareHolder: "",
  reduceShareType: "",
  reduceValueShare: "",
  reduceShareReduction: "",
  reduceRemark: "",
  reduceDocLink: "",
  reduceDocId: "",
  reduceAttachment: "",
  reduceAttachmentKey: "",
  reduceDocType: "",
  reduceDocDate: "",
  reduceNotes: "",
  convertResolutionDate: "",
  convertShareHolder: "",
  convertShareType: "",
  convertToShare: "",
  convertCurrency: "",
  convertValueShare: "",
  convertNoShares: "",
  convertRemark: "",
  convertDocLink: "",
  convertDocId: "",
  convertAttachment: "",
  convertAttachmentKey: "",
  convertDocType: "",
  convertDocDate: "",
  convertNotes: "",
  dummy: "",
  shareId:'',
  shareDelete:false,
  historyData: "",
  shareResolutionList:[],
  shareRegisterListDuplicate:[],
  totalShare:{},
};

export const ShareReg = (state = init, action) => {
  switch (action.type) {
    case "SHARE_INFO":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
