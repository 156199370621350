import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/Client";
import * as settings from "../../../actions/Settings";

import "react-datepicker/dist/react-datepicker.css";

function BulkUpload(props) {
  useEffect(() => {
    props.reset_client_info();
    if (props.addClientStatus === 1) {
      setTimeout(function () {
        props.setFieldValues("addClientStatus", 0);
        props.setFieldValues("bulk_upload", false);
        props.reset_client_info();
      }, 2000);
    }
  }, [props.addClientStatus]);
  const bulkClientUpload = (event) => {
    var userData = JSON.parse(localStorage.getItem("userData"));
    event.preventDefault();
    if (validClientInfo()) {
      props.addBulkClient({
        attachment: props.attachment,
      });
    }
  };

  const validClientInfo = () => {
    let valid = true;
    let errors = props.addClientErrors;
    if (!props.attachment) {
      valid = false;
      errors.attachment = "Cannot be Empty";
    } else {
      errors.attachment = "";
    }
    props.updateClientErrors(errors);
    return valid;
  };

  return (
    <Modal
      show={props.show}
      className="modal bootstrap-dialog type-primary size-normal "
      role="dialog"
      aria-hidden="true"
      id="newclient"
      aria-labelledby="newclient"
      tabIndex={-1}
    //   onHide={() => props.setFieldValues("bulk_upload", false)}
    >
      <div className="modal-content">
        <div className="modal-header bootstrap-dialog-draggable">
          <div className="bootstrap-dialog-header">
            <div
              className="bootstrap-dialog-close-button"
              style={{ display: "none" }}
            >
              <button className="close" aria-label="close">
                ×
              </button>
            </div>
            <div
              className="bootstrap-dialog-title"
              id="6515262a-6027-4e02-a065-90e1c9e04a75_title"
            >
              Bulk Upload
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="bootstrap-dialog-body">
            <div className="bootstrap-dialog-message">
              <div>
                <form
                  method="POST"
                  action="https://crm2.gbsc.geneasol-apps.info/client/clients?"
                  acceptCharset="UTF-8"
                  encType="multipart/form-data"
                  className="form-horizontal"
                  id="form"
                >
                  <input
                    name="_token"
                    type="hidden"
                    defaultValue="wQUZACCGN0G9CyyGsQ55geMLv6LTTQDh3sskflCL"
                  />

                  <div className="form-group">
                    <label
                      htmlFor="original_filename"
                      className="control-label col-sm-4"
                    >
                      Excel
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        accept=".xlsx, .csv, .xls"
                        name="original_filename"
                        type="file"
                        id="original_filename"
                        onChange={(e) => {
                          if (1) {
                            props.setFieldValues(
                              "attachment",
                              e.target.files[0]
                            );
                          }
                        }}
                      />
                      {props.addClientErrors.attachment &&
                      props.addClientErrors.attachment.length > 0 ? (
                        <span className="text-danger">
                          {props.addClientErrors.attachment}
                        </span>
                      ) : (
                        ""
                      )}
                      {/* <p className="help-block">
                        * Uploading of file limited to 2MB only.{" "}
                      </p> */}
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      name="client_id_doc"
                      type="hidden"
                    />
                  </div>
                  <div className="col-sm-12">
                    {props.clientApiStatus ? (
                      <div
                        className={`alert ${
                          props.clientApiStatus === 1 ? "" : "alert-danger"
                        }`}
                        role="alert"
                      >
                        {props.clientApiStatus === 1 ? "" : props.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    className="btn form-control btn-success"
                    id="addnew"
                    value="new"
                    name="action"
                    type="button"
                    onClick={(e) => {
                      // props.changeCompanyInfo("addCompanyStatus", 1);
                      bulkClientUpload(e);
                    }}
                  >
                    <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="bootstrap-dialog-footer">
            <div className="bootstrap-dialog-footer-buttons">
              <button
                onClick={() => props.setFieldValues("bulk_upload", false)}
                className="btn form-control"
                id="f86f3f7f-ac8a-417d-9cba-2410e5baee15"
                data-dismiss="modal"
              >
                <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    show: state.Client.bulk_upload,
    company_name: state.Client.company_name,
    registration_no: state.Client.registration_no,
    incorporation_date: state.Client.incorporation_date,
    currency: state.Client.currency,
    clientstatus: state.Client.clientstatus,
    address: state.Client.address,
    business_address: state.Client.business_address,
    referral: state.Client.referral,
    remarks: state.Client.remarks,
    flag: state.Client.flag,
    document_id: state.Client.document_id,
    attachment: state.Client.attachment,
    attachment_keyword: state.Client.attachment_keyword,
    document_type: state.Client.document_type,
    document_date: state.Client.document_date,
    notes: state.Client.notes,
    description: state.Client.description,
    addClientErrors: state.Client.addClientErrors,
    addClientStatus: state.Client.addClientStatus,
    currencyList: state.Client.currencyList,
    List1: state.Client.List1,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    DocumentTypeList: state.Settings.DocumentTypeList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getDocumentList: () => dispatch(settings.getDocumentClassification()),
    setFieldValues: (f, v) => dispatch(actions.changeClientInfo(f, v)),
    addBulkClient: (data) => dispatch(actions.addBulkClient(data)),
    updateClientErrors: (data) => dispatch(actions.updateClientErrors(data)),
    reset_client_info: () => dispatch(actions.reset_client_info()),
    getCurrencyList: () => dispatch(actions.getCurrencyList()),
    getClientStatus: () => dispatch(actions.getClientStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkUpload);
