import React, { Fragment, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../../actions/UserManagement";

function Removecompany(props) {
  const removeCompany = (e) => {
    e.preventDefault();
    if (validCompanyExists()) {
      props.removeCompanyFromUser({
        user_id: props.user_id,
      });
    }
  };

  const validCompanyExists = () => {
    let valid = true;
    let errors = props.removeCompanyErrors;
    if (!props.assignedCompanyList.length > 0) {
      valid = false;
      errors.company_name = "No Company Assigned";
    } else {
      errors.company_name = "";
    }
    props.updateRemoveCompanyErrors(errors);
    return valid;
  };
  const addCompany = (e) => {
    e.preventDefault();
    if (validItemInfo()) {
      props.assignCompanyToUser({
        company_id: props.company_id,
        user_id: props.user_id,
      });
    }
  };

  const validItemInfo = () => {
    let valid = true;
    let errors = props.removeCompanyErrors;
    if (!props.company_id) {
      valid = false;
      errors.select_company = "Select Company";
    } else {
      errors.select_company = "";
    }
    props.updateRemoveCompanyErrors(errors);
    return valid;
  };

  // console.log("rum",props.assignedCompanyList)

  return (
    <Modal
      show={props.removeCompanyShow}
      onHide={() => props.setValues("removeCompanyShow", false)}
      className="modal bootstrap-dialog type-primary fade size-normal in"
      role="dialog"
      aria-hidden="true"
      id="0e0bcb60-66c4-49c5-8496-f31ad4ebe975"
      aria-labelledby="0e0bcb60-66c4-49c5-8496-f31ad4ebe975_title"
      tabIndex={-1}
      style={{ zIndex: 1050, display: "block" }}
    >
      <div className="modal-content">
        <div className="modal-header bootstrap-dialog-draggable">
          <div className="bootstrap-dialog-header">
            <div
              className="bootstrap-dialog-close-button"
              style={{ display: "none" }}
            >
              <button className="close" aria-label="close">
                ×
              </button>
            </div>
            <div
              className="bootstrap-dialog-title"
              id="0e0bcb60-66c4-49c5-8496-f31ad4ebe975_title"
            >
              Remove Company
            </div>
          </div>
        </div>
        <div className="modal-body">
          {props.userApiStatus ? (
            <div
              className={`alert ${props.userApiStatus === 1 ? "" : "alert-danger"
                }`}
              role="alert"
            >
              {props.userApiStatus === 1 ? '' : props.message}
            </div>
          ) : (
            ""
          )}
          <div className="bootstrap-dialog-body">
            <div className="bootstrap-dialog-message">
              <div className="bootstrap-dialog-body">
                <form
                  method="POST"
                  action="https://crm2.gbsc.geneasol-apps.info/admin/companyuser/2"
                  acceptCharset="UTF-8"
                  className="form-horizontal"
                >
                  <input name="_method" type="hidden" defaultValue="PUT" />
                  <input
                    name="_token"
                    type="hidden"
                    defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446"
                  />
                  <div className="form-group">
                    <label htmlFor="assignedcompany" className="col-sm-4">
                      Assigned Company
                    </label>
                    <div className="col-sm-8">
                      <select
                        className="form-control"
                        onChange={(e) =>
                          props.setValues("company_id", e.target.value)
                        }
                        required="required"
                        id="assignedCompanyId"
                        name="assignedCompanyId"
                        value={props.assignedCompany}
                      >
                        <option value="">Select Company</option>
                        {props.assignedCompanyList.length > 0 &&
                          props.assignedCompanyList.map((com) => {
                            return (
                              <option value={com.id}>{com.company_name}</option>
                            );
                          })}
                      </select>
                      {props.removeCompanyErrors.company_name &&
                        props.removeCompanyErrors.company_name.length > 0 ? (
                        <span className="text-danger">
                          {props.removeCompanyErrors.company_name}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <button
                    className="form-control"
                    type="button"
                    id="removeassignment"
                    onClick={(e) => removeCompany(e)}
                  >
                    Remove
                  </button>
                  <div className="form-group" style={{ paddingTop: "20px" }}>
                    <label htmlFor="addcompany" className="col-sm-4">
                      Add To Company
                    </label>
                    <div className="col-sm-8">
                      <select
                        className="form-control"
                        onChange={(e) =>
                          props.setValues("company_id", e.target.value)
                        }
                        required="required"
                        id="addCompanyId"
                        name="addCompanyId"
                        value={props.company_id}
                      >
                        <option value="">Select Company</option>
                        {props.companyList.length > 0 &&
                          props.companyList.map((com) => {
                            return (
                              <option value={com.id}>{com.company_name}</option>
                            );
                          })}
                      </select>
                      {props.removeCompanyErrors.select_company &&
                        props.removeCompanyErrors.select_company.length > 0 ? (
                        <span className="text-danger">
                          {props.removeCompanyErrors.select_company}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <button
                    className="form-control"
                    type="button"
                    id="addassignment"
                    onClick={(e) => addCompany(e)}
                  >
                    Add
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="bootstrap-dialog-footer">
            <div className="bootstrap-dialog-footer-buttons">
              <button
                onClick={() => {
                  props.setValues("removeCompanyShow", false);
                  props.reset_remove_info();
                }}
                className="btn form-control"
                id="a4444af6-ebc4-4e34-b6ee-9aac0ccd1e4f"
              >
                <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    removeCompanyShow: state.UserDatas.removeCompanyShow,
    companyList: state.CompanySetups.companyList,
    usersList: state.UserDatas.usersList,
    company_id: state.UserDatas.company_id,
    user_id: state.UserDatas.user_id,
    assignedCompanyList: state.UserDatas.assignedCompanyList,
    assignedCompany: state.UserDatas.assignedCompany,
    removeCompanyErrors: state.UserDatas.removeCompanyErrors,
    userApiStatus: state.UserDatas.userApiStatus,
    message: state.UserDatas.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setValues: (f, v) => dispatch(actions.changeUserInfo(f, v)),
    assignCompanyToUser: (data) => dispatch(actions.assignCompanyToUser(data)),
    reset_remove_info: () => dispatch(actions.reset_remove_info()),
    updateRemoveCompanyErrors: (data) =>
      dispatch(actions.updateRemoveCompanyErrors(data)),
    removeCompanyFromUser: (data) =>
      dispatch(actions.removeCompanyFromUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Removecompany);
