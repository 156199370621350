import { Modal } from "react-bootstrap";
import React, { Fragment, useEffect, useState } from "react";
import ApiLinks from '../../../actions/common/Api_Links'
import { sessionGet } from "../../../actions/common/TokenValidation";

import axios from 'axios'

export const StatementTable = ({selectedTableDetails: { document_id, user_id }, show, setShow}) => {
    const token = sessionGet('token')
    const [table, setTable] = useState([]);
    
    const getTable = async () => {
        try {
            const { data } = await axios.get(`${ApiLinks.getStatementTable}?document_id=${document_id}&user_id=${user_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            console.log(data)
            setTable(data.result)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getTable()
    }, [])


    return show === true && (
        <Modal
      show={show}
      size='xl'
      className="modal bootstrap-dialog type-primary"
      role="dialog"
      aria-hidden="true"
      id="upload-modal"
      aria-labelledby="upload-modal"
      tabIndex={-1}
      onHide={() => {
        setShow(false)
      }}
    >
        <div className="statement-table-modal-container">
            {
                table.length > 0 && (
                <>
                <table>
                    <thead>
                        <td>Date</td>
                        <td>Amount</td>
                        <td>Payee</td>
                        <td>Description</td>
                        <td>References</td>
                        <td>Check Number</td>
                    </thead>
                    <tbody>
                    {
                        table.map((row, index) => {
                            return <tr key={index}>
                                <td>{row.date}</td>
                                <td>{row.amount}</td>
                                <td>{row.payee}</td>
                                <td>{row.description}</td>
                                <td>{row.references}</td>
                                <td>{row['check_number']}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>   
                </>        
                )
            }
        </div>

    </Modal>
    )
}