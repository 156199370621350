import React from "react";
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
// import { TitleBar } from './title-bar';
// import { isNullOrUndefined } from "@syncfusion/ej2-base";
// import { handleInputChange } from "react-select/dist/index-ea9e225d.cjs.prod";
import { registerLicense } from "@syncfusion/ej2-base";
// import DefaultData from "./data"
// Registering Syncfusion license key.
registerLicense('ORg4AjUWIQA/Gnt2V1hhQlJAfVddXGNWfFN0RnNYfVR0dV9CZUwgOX1dQl9gSXlSd0VjXX1eeHFUQWk=');
DocumentEditorContainerComponent.Inject(Toolbar);

export default class DocumentEditor extends React.Component {
  // titleBar: TitleBar;
  // container: DocumentEditorContainerComponent;
  container;
  constructor(props) {
    super(props);
    this.state = {
      contentChanged: false,
    };
  }

  componentDidMount() {
    this.openTemplate();
  }

  componentDidUpdate(prevProps) {
    if (this.props.rendr) {
      this.openTemplate();
    }
  }

  openTemplate() {
    var dataContext = this;
    dataContext.container.documentEditor.open(this.props?.fileJson);
    this.onContentChange();  
    this.props.setRendr(false);
  }

  onContentChange() {
    // this.setState({
    //   contentChanged: true,
    // });
    // console.log("contentChanged",this.state.contentChanged)
    // if (this.contentChanged) {

    //You can save the document as below
    // this.container.documentEditor.saveAsBlob("Docx").then(async(blob = Blob) => {
    //   console.log("Saved sucessfully");
    //   let exportedDocument = await blob.text();

    this.container.documentEditor.saveAsBlob("Docx").then((blob = Blob) => {
      // let exportedDocument = blob;
      const myFile = new File([blob], `${this.props.temName ? this.props.temName : `temp_${Date.now()}`}.docx`, {
        type: blob.type,
      });

      // let formData = new FormData();
      // formData.append("upload_file", "sample.docx");
      // formData.append("upload_file", exportedDocument);

      this.props.setTemplateData(myFile);

      //Now, save the document where ever you want.
      /* tslint:disable */
      // var req = new XMLHttpRequest();
      // // Replace your running Url here
      // req.open(
      //   "POST",
      //   "http://54.251.142.179:6007/api/v1/s3_image_upload",
      //   true
      // );
      // req.onreadystatechange = () => {
      //   if (req.readyState === 4) {
      //     if (req.status === 200 || req.status === 304) {
      //       console.log("Saved sucessfully");
      //     }
      //   }
      // };
      // req.send(formData);
    });
    this.contentChanged = false;
    // }
  }

  render() {
    return (
      <div>
        <div id="default_title_bar" className="e-de-ctn-title"></div>
        <DocumentEditorContainerComponent
          id="container"
          ref={(scope) => {
            this.container = scope;
          }}
          serviceUrl={
            "https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
          }
          height={"600px"}
          width={"100%"}
          style={{ width: "50%" }}
          showPropertiesPane={false}
          contentChange={this.onContentChange.bind(this)}
        />
      </div>
    );
  }
}
