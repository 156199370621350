import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import {
  personal_data,
  getIdentificationType,
  editPersonal,
  getDesignation,
  getSalutation,
  getNationalities,
  getFlag,
  addPersonal,
} from "../../../actions/clientDetails/Personal";
import * as settings from "../../../actions/Settings";
import * as client from "../../../actions/Client";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import $ from "jquery";
import 'jquery-ui-dist/jquery-ui';

function EditPersonalData(props) {
  useEffect(() => {
    window.$(document).ready(function () {
      $("#editPersonal").draggable({
        handle: ".modal-header"
      });
    })
  })
  useEffect(() => {
    props.identificationType();
    props.getDesignation();
    props.getSalutation();
    props.getNationalities();
    props.getFlag();
    props.getDocumentList();
    props.allListDocuments(sessionStorage.getItem("personalId"))
   
  }, []);
  const getfilename=(e)=>{
    var splitedval=(e.target.value).split("_")
   
    props.sendValues("documentLink",e.target.value)
    var index = e.nativeEvent.target.selectedIndex;

    if(e.target.value != 0 && e.target.value != 1){
      props.sendValues("documentLink",e.target.value)
      props.alldocumentclientList && props.alldocumentclientList.map((filepathresultnn,indexvalnn)=>{
      
        if(splitedval[0] == filepathresultnn.id){
          document.getElementById("viewdocument").style.display="block";
          document.getElementById("viewdocument").href=filepathresultnn.file_path;
        }
      })
    }else{
      document.getElementById("viewdocument").style.display="none";
    }
    
  }
  
  const editFunc = (event) => {
    event.preventDefault();
    var userData = JSON.parse(localStorage.getItem("userData"));
    let document_date = "";
    if (props.documentDate != '') {
      document_date = moment(props.documentDate).format('YYYY-MM-DD')
    };

    let copystatus = "", copystatus_value = "";
    if (props.copiedStatus == true) {
      copystatus = 1
    } else {
      copystatus = 0
    };
    if (props.copiedStatus_value == true) {
      copystatus_value = 1;
    } else {
      copystatus_value = 0;
    }

    let data = {
      company_id: userData.company_id,
      client_id: props.clientId ? props.clientId : sessionStorage.getItem("personalId"),
      office_bearer_id: props.personal_id,
      identification_no: props.idNo,
      identification_type_id: props.idType,
      id_date_of_issue: moment(props.dateIssue).format("YYYY-MM-DD"),
      id_date_of_expiry: moment(props.dateExpiry).format("YYYY-MM-DD"),
      designation_id: props.designation,
      status: props.status,
      salutation_id: props.salutation,
      name: props.name,
      address: props.address,
      nationality_id: props.nationality,
      apponitment_date: moment(props.dateAppointment).format("YYYY-MM-DD"),
      resignation_date: moment(props.dateResignation).format("YYYY-MM-DD"),
      acra_apponitment_date: moment(props.appointmentAcradate).format("YYYY-MM-DD"),
      acra_resignation_date: moment(props.resignationAcradate).format("YYYY-MM-DD"),
      date_of_birth: moment(props.dateBirth).format("YYYY-MM-DD"),
      email: props.email,
      phone: props.phone,
      flag: props.flag,
      flag_remarks: props.flagRemark,
      document_upload: props.documentUpload,
      document_link: props.documentLink,
      person_login_status: copystatus_value,
      person_copied_status: copystatus,
      active_tab: props.pageSelected,
      document_link: props.documentLink,
      document_id: props.documentId,
      attachment: props.attachment,
      attachment_keyword: props.documentKey,
      document_type: props.documentType,
      document_date: document_date,
      notes: props.note,
      description: props.description,
      smsOTPStatus: props.smsOTPStatus,
      emailOTPStatus: props.emailOTPStatus,
    };

    console.log("check", data);

    props.EditPersonal(data);
  };

  return (
    <Modal
      show={props.editPersonal}
      onHide={() => {
        props.sendValues("editPersonal", false)
        props.sendValues("personal_id", '')
      }}
      className="modal bootstrap-dialog type-primary fade size-normal in"
      role="dialog"
      aria-hidden="true"
      id="editPersonal"
      aria-labelledby="editPersonal"
      tabIndex={-1}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bootstrap-dialog-draggable">
            <div className="bootstrap-dialog-header">
              <div
                className="bootstrap-dialog-close-button"
                style={{ display: "none" }}
              >
                <button className="close" aria-label="close">
                  ×
                </button>
              </div>
              {props.officeBearer == true ? (
                <div
                  className="bootstrap-dialog-title"
                  id="dbe3cda7-7fe1-4237-8d29-d1fa184efa50_title"
                >
                  Edit Office Bearer
                </div>
              ) : (
                <div
                  className="bootstrap-dialog-title"
                  id="dbe3cda7-7fe1-4237-8d29-d1fa184efa50_title"
                >
                  Edit Personal
                </div>
              )}
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">
                <div className="bootstrap-dialog-body">
                  <form
                    onSubmit={(e) => editFunc(e)}
                    id="form"
                    className="form-horizontal"
                  >
                    <input name="_method" type="hidden" defaultValue="PUT" />
                    <input
                      name="_token"
                      type="hidden"
                      defaultValue="SqJVpG2DVmPku1TQH8Iwf6iCZ037h2xGM6rKwjYT"
                    />
                    <div className="form-group">
                      <label
                        htmlFor="identification"
                        className="control-label col-sm-4 required"
                      >
                        Identification No
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          value={props.idNo}
                          disabled={`${props?.idNo?.length > 0 ? true : ""}`}
                          onChange={(e) =>
                            props.sendValues("idNo", e.target.value)
                          }
                          // readOnly={1}
                          required="required"
                          name="identification"
                          type="text"
                          defaultValue="S7339946G"
                          id="identification"
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="identificationtype"
                        className="control-label col-sm-4 required"
                      >
                        Identification Type
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.idType}
                          onChange={(e) =>
                            props.sendValues("idType", e.target.value)
                          }
                          disabled={1}
                          required="required"
                          id="identificationtype"
                          name="identificationtype"
                        >
                          <option value="">=== Select ===</option>
                          {props.idList.map((identi, idx) => {
                            return (
                              <option key={idx} value={identi.id}>
                                {identi.Name}
                              </option>
                            );
                          })}
                        </select>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="identification_date_of_issue"
                        className="control-label col-sm-4 required"
                      >
                        ID Date of Issue
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.dateIssue}
                          locale={"en"}
                          peekNextMonth
                          disabled={1}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("dateIssue", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() => props.sendValues("dateIssue", "")}
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="identification_date_of_expiry"
                        className="control-label col-sm-4"
                      >
                        ID Date of Expiry
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.dateExpiry}
                          locale={"en"}
                          peekNextMonth
                          disabled={1}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("dateExpiry", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() => props.sendValues("dateExpiry", "")}
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="employeetype_id"
                        className="control-label col-sm-4 required"
                      >
                        Designation
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.designation}
                          onChange={(e) =>
                            props.sendValues("designation", e.target.value)
                          }
                          required="required"
                          id="employeetype_id"
                          name="employeetype_id"
                        >
                          <option value>=== Select ===</option>
                          {props.designationList.map((desig, idx) => {
                            return (
                              <option key={idx} value={desig.id}>
                                {desig.Name}
                              </option>
                            );
                          })}
                        </select>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="currency_id"
                        className="control-label col-sm-4 required"
                      >
                        Status
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          required="required"
                          id="currency_id"
                          name="currency_id"
                          value={props.status}
                          onChange={(e) =>
                            props.sendValues("status", e.target.value)
                          }
                        >
                          <option value="">Select Client status</option>
                          {props.List1.map((cur) => {
                            return <option value={cur.id} selected={`${props.status} == ${cur.id}?selected:""`}>{cur.Name}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="salutation"
                        className="control-label col-sm-4 required"
                      >
                        Salutation
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.salutation}
                          onChange={(e) =>
                            props.sendValues("salutation", e.target.value)
                          }
                          disabled={1}
                          required="required"
                          id="salutation"
                          name="salutation"
                        >
                          <option value>=== Select ===</option>
                          {props.salutationList.map((sal, idx) => {
                            return (
                              <option key={idx} value={sal.id}>
                                {sal.Name}
                              </option>
                            );
                          })}
                        </select>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="employeename"
                        className="control-label col-sm-4 required"
                      >
                        Name
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          value={props.name}
                          onChange={(e) =>
                            props.sendValues("name", e.target.value)
                          }
                          disabled={1}
                          required="required"
                          name="employeename"
                          type="text"
                          defaultValue="GERARD S/O D.THANARAJ"
                          id="employeename"
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="address"
                        className="control-label col-sm-4 required"
                      >
                        Address
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          value={props.address}
                          onChange={(e) =>
                            props.sendValues("address", e.target.value)
                          }
                          rows={3}
                          disabled={1}
                          required="required"
                          name="address"
                          cols={50}
                          id="address"
                          defaultValue={
                            "62 WOODLANDS DRIVE 16 #11-28, LA CASA SINGAPORE (737895)"
                          }
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="nationality"
                        className="control-label col-sm-4 required"
                      >
                        Nationality
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.nationality}
                          onChange={(e) =>
                            props.sendValues("nationality", e.target.value)
                          }
                          disabled={1}
                          required="required"
                          id="nationality"
                          name="nationality"
                        >
                          <option value>=== Select ===</option>
                          {props.nationalitiesList.map((nat, idx) => {
                            return (
                              <option key={idx} value={nat.id}>
                                {nat.Name}
                              </option>
                            );
                          })}
                        </select>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="dateofappointment"
                        className="control-label col-sm-4 required"
                      >
                        Date of Appointment
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.dateAppointment}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("dateAppointment", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() =>
                              props.sendValues("dateAppointment", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="dateofresignation"
                        className="control-label col-sm-4"
                      >
                        Date of Resignation
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.dateResignation}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("dateResignation", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() =>
                              props.sendValues("dateResignation", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="appointment_acra_filed_date"
                        className="control-label col-sm-4 required"
                      >
                       Appointment Acra Filed Date
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.appointmentAcradate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("appointmentAcradate", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() => props.sendValues("appointmentAcradate", "")}
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="resignation_acra_filed_date"
                        className="control-label col-sm-4 required"
                      >
                       Resignation Acra Filed Date
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.resignationAcradate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("resignationAcradate", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() => props.sendValues("resignationAcradate", "")}
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                       
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="dateofbirth"
                        className="control-label col-sm-4 required"
                      >
                        Date of Birth
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.dateBirth}
                          locale={"en"}
                          disabled={1}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("dateBirth", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() => props.sendValues("dateBirth", "")}
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="email"
                        className="control-label col-sm-4 required"
                      >
                        Email
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          value={props.email}
                          required="required"
                          onChange={(e) =>
                            props.sendValues("email", e.target.value)
                          }
                          name="email"
                          type="email"
                          defaultValue="GERARD@GBSC.COM.SG"
                          id="email"
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="mobile"
                        className="control-label col-sm-4 required"
                      >
                        Phone
                      </label>
                      <div className="col-sm-8">
                         <PhoneInput
                          value={props.phone}

                          // className="form-control"
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="SG"
                          onChange={phone => props.sendValues("phone", phone)}
                          // onChange={(e) =>
                          //   props.sendValues("phone", e.target.value || undefined)
                          // }
                          required="required"
                          name="mobile"
                          type="tel"
                          id="mobile"
                          defaultValue = {  props.phone }
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        id="copied"
                        name="copied"
                        type="checkbox"
                        checked={props.copiedStatus}
                        onChange={(e) =>
                          props.sendValues("copiedStatus", e.target.checked)
                        }
                      />
                      <label
                        htmlFor="copied"
                        className="control-label col-sm-4"
                      >
                        To place this person in copied
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        id="emailOTPStatus"
                        name="emailOTPStatus"
                        type="checkbox"
                        checked={props.emailOTPStatus}
                        defaultValue={1}
                        onChange={(e) =>
                          props.sendValues("emailOTPStatus", e.target.checked)
                        }
                      />
                      <label
                        htmlFor="emailOTPStatus"
                        className="control-label col-sm-4"
                      >
                        Email OTP
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        id="smsOTPStatus"
                        name="smsOTPStatus"
                        type="checkbox"
                        checked={props.smsOTPStatus}
                        defaultValue={1}
                        onChange={(e) =>
                          props.sendValues("smsOTPStatus", e.target.checked)
                        }
                      />
                      <label
                        htmlFor="smsOTPStatus"
                        className="control-label col-sm-4"
                      >
                        SMS OTP
                      </label>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="flag_id"
                        className="control-label col-sm-4 required"
                      >
                        Flag
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.flag}
                          onChange={(e) =>
                            props.sendValues("flag", e.target.value)
                          }
                          disabled={1}
                          required="required"
                          id="flag_id"
                          name="flag_id"
                        >
                          <option value>=== Select ===</option>
                          {props.flagList.map((fl, idx) => {
                            return (
                              <option key={idx} value={fl.id}>
                                {fl.Name}
                              </option>
                            );
                          })}
                        </select>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="flag_text"
                        className="control-label col-sm-4"
                      >
                        Flag Remarks
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          value={props.flagRemark}
                          disabled={1}
                          onChange={(e) =>
                            props.sendValues("flagRemark", e.target.value)
                          }
                          name="flag_text"
                          type="text"
                          id="flag_text"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        id="copiedStatus_value"
                        name="copiedStatus_value"
                        type="checkbox"
                        checked={props.copiedStatus_value}
                        onChange={(e) =>
                          props.sendValues(
                            "copiedStatus_value",
                            e.target.checked
                          )
                        }
                      />
                      <label
                        htmlFor="copiedStatus_value"
                        className="control-label col-sm-4"
                      >
                        Do you want to provide the login to the system
                      </label>
                    </div>
                    <div className="form-group">
                      <div
                        className="text-danger lead strong col-xs-12 col-sm-12 col-md-12 col-lg-12"
                        id="errortext"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="global_status"
                        className="control-label col-sm-4 required"
                      >
                        Document Upload To
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.documentUpload}
                          required="required"
                          onChange={(e) =>
                            props.sendValues("documentUpload", e.target.value)
                          }
                          id="global_status"
                          name="global_status"
                        >
                          <option value={1}>Global</option>
                          <option value={2} selected="selected">
                            Local
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="document_link"
                        className="control-label col-sm-4"
                      >
                        Document Link
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.documentLink}
                          onChange={(e) => getfilename(e)
                          }
                          id="document_link"
                          name="document_link"
                        >
                          <option value={0} selected="selected">
                            View from the drop-down list / Select New Doc to upload new
                          </option>
                          <option value={1}>New Document</option>
                          {
                            props.alldocumentclientList && props.alldocumentclientList.map((filepathresult,index)=>{
                              return(<>
                             <option value= {filepathresult.id+"_"+filepathresult.document_type+"_"+filepathresult.indexno}> {filepathresult.file_path.split('/').pop().split('?')[0].split('#')[0].replace(/[/\\?%*:|"<>]/g, '-') }</option>
                              </>);
                            })
                          }
                        </select>
                        <a href="" id="viewdocument" style={{display:"none"}} target="_blank">View Selected Document</a>
                      </div>
                    </div>
                    {props.documentLink == 1 ? (
                      <div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_id"
                            className="control-label col-sm-4"
                          >
                            Document ID
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              value={props.documentId}
                              onChange={(e) =>
                                props.sendValues("documentId", e.target.value)
                              }
                              name="document_id"
                              type="text"
                              id="document_id"
                            />
                            <p className="help-block">
                              Must be unique ID with this client{" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="original_filename"
                            className="control-label col-sm-4"
                          >
                            Attachment
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              onChange={(e) => {
                                if (e.target.files[0].size <= 2000000) {
                                  props.sendValues(
                                    "attachment",
                                    e.target.files[0]
                                  )
                                } else {
                                  props.sendValues("attachment", '');
                                  e.target.value = '';
                                  props.sendError("clientApiStatus", 2);
                                  props.sendError("message", "File size limit only 2Mb");
                                  setTimeout(() => {
                                    props.sendError("clientApiStatus", 0);
                                    props.sendError("message", "");
                                  }, 2500);
                                }
                              }}
                              maxLength={4096}
                              accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif"
                              name="original_filename"
                              type="file"
                              id="original_filename"
                            />
                            <p className="help-block">
                              * Uploading of file limited to 2MB only.{" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label htmlFor="document_tag" className="control-label col-sm-4">Description</label>
                          <div className="col-sm-8">
                            <textarea className="form-control"                              
                             value={props.description} 
                             onChange={(e) => { props.sendValues("description", e.target.value) }} 
                             rows={3} 
                             name="document_tag" 
                             cols={50}
                              id="document_tag"
                               defaultValue={""} />
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="keywords"
                            className="control-label col-sm-4"
                          >
                            Attachment Keyword for Searching Purposes
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              value={props.documentKey}
                              onChange={(e) =>
                                props.sendValues("documentKey", e.target.value)
                              }
                              rows={3}
                              name="keywords"
                              cols={50}
                              id="keywords"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="document_type_id"
                            className="control-label col-sm-4"
                          >
                            Document Type
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              value={props.documentType}
                              id="document_type_id"
                              name="document_type_id"
                              onChange={(e) =>
                                props.sendValues("documentType", e.target.value)
                              }
                            >
                              <option value='' >
                                === Select ===
                              </option>
                              {props.DocumentTypeList.map((val) => {
                                return (
                                  <option value={val.id}>{val.Name}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="document_date"
                            className="control-label col-sm-4"
                          >
                            Resolution/Document Date
                          </label>
                          <div className="col-sm-8">
                            <DatePicker
                              showPopperArrow={false}
                              dateFormat={"dd/MM/yyyy"}
                              selected={props.documentDate}
                              locale={"en"}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: "0px, 0px",
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: "viewport",
                                },
                              }}
                              onChange={(Date) => {
                                props.sendValues("documentDate", Date);
                              }}
                            >
                              <div
                                className="trash-icon"
                                onClick={() =>
                                  props.sendValues("documentDate", "")
                                }
                              >
                                <span class="glyphicon glyphicon-trash"></span>
                              </div>
                            </DatePicker>
                            <p className="help-block">DD/MM/YYYY </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_tag"
                            className="control-label col-sm-4"
                          >
                            Notes
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              rows={3}
                              name="document_tag"
                              value={props.note}
                              onChange={(e) =>
                                props.sendValues("note", e.target.value)
                              }
                              cols={50}
                              id="document_tag"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="client_id_doc"
                        type="hidden"
                        defaultValue={489}
                      />
                    </div>
                    <div className="col-sm-12" >
                      {props.clientApiStatus ? (
                        <div
                          className={`alert ${props.clientApiStatus === 1 ? "" : "alert-danger"
                            }`}
                          role="alert"
                        >
                          {props.clientApiStatus === 1 ? "" : props.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <button
                      className="btn form-control btn-success"
                      type="submit"
                      id="edit"
                      value="update"
                      name="action"
                    >
                      <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                      Update
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  className="btn form-control"
                  id="163ec868-3f96-4df2-92ad-cbf9f374c74f"
                  onClick={() => {
                    props.sendValues("editPersonal", false)
                    props.sendValues("personal_id", '')
                    document.location.reload()
                  }
                  }
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    editPersonal,
    clientId,
    idNo,
    idType,
    dateIssue,
    appointmentAcradate,
    resignationAcradate,
    dateExpiry,
    designation,
    status,
    salutation,
    name,
    address,
    nationality,
    dateAppointment,
    dateResignation,
    dateBirth,
    email,
    phone,
    flag,
    flagRemark,
    documentUpload,
    documentLink,
    idList,
    designationList,
    salutationList,
    nationalitiesList,
    flagList,
    personal_id,
    documentId,
    attachment,
    documentKey,
    documentType,
    documentDate,
    note,
    description,
    copiedStatus,
    editPersonalStatus,
    officeBearer,
    smsOTPStatus,
    emailOTPStatus,
    copiedStatus_value
  } = state.Personal;
  return {
    editPersonal,
    idNo,
    idType,
    dateIssue,
    appointmentAcradate,
    resignationAcradate,
    dateExpiry,
    designation,
    status,
    salutation,
    name,
    address,
    nationality,
    dateAppointment,
    dateResignation,
    dateBirth,
    email,
    phone,
    flag,
    flagRemark,
    documentUpload,
    documentLink,
    clientId,
    idList,
    designationList,
    salutationList,
    nationalitiesList,
    flagList,
    personal_id,
    documentId,
    attachment,
    documentKey,
    documentType,
    documentDate,
    note,
    description,
    editPersonalStatus,
    copiedStatus,
    officeBearer,
    pageSelected: state.Client.pageSelected,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    DocumentTypeList: state.Settings.DocumentTypeList,
    alldocumentclientList: state.Client.alldocumentclientList,
    List1: state.Client.List1,
    smsOTPStatus,
    emailOTPStatus,
    copiedStatus_value
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (f, v) => dispatch(personal_data(f, v)),
    sendError: (f, v) => dispatch(client.changeClientInfo(f, v)),
    identificationType: () => dispatch(getIdentificationType()),
    getDesignation: () => dispatch(getDesignation()),
    getSalutation: () => dispatch(getSalutation()),
    getNationalities: () => dispatch(getNationalities()),
    getFlag: () => dispatch(getFlag()),
    EditPersonal: (data) => dispatch(editPersonal(data)),
    getDocumentList: () => dispatch(settings.getDocumentClassification()),
    allListDocuments: (data) => dispatch(client.allListDocuments(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPersonalData);
