const init = {
  personalList: [],
  editPersonal: false,
  addPersonal: false,
  deletePersonal: false,
  nothing_modal_show: false,
  confirmation_modal_show: false,
  nothing_select:false,
  approve_modal : false,
  approveStatus : '',
  deletePersonalStatus : '',
  addPersonalStatus:'',
  editPersonalStatus:"",
  idNo: "",
  clientId: "",
  idType: "",
  dateIssue: "",
  dateExpiry: "",
  designation: "",
  salutation: "",
  name: "",
  address: "",
  nationality: "",
  dateAppointment: "",
  dateResignation: "",
  dateBirth: "",
  email: "",
  phone: "",
  flag: "",
  flagRemark: "",
  documentUpload: "",
  documentLink: "",
  idList: [],
  designationList: [],
  salutationList: [],
  nationalitiesList: [],
  flagList: [],
  delId: "",
  personal_id: "",
  personalStatus: 0,
  officeBearer:false,
  documentId:'',
  attachment:'',
  documentKey:'',
  documentType:'',
  documentDate:'',
  note:'',
  copiedStatus:false,
  smsOTPStatus: 0,
  emailOTPStatus: 1,
  copiedStatus_value: false
};

export const Personal = (state = init, action) => {
  switch (action.type) {
    case "PERSONAL_LIST":
      return { ...state, personalList: action.data };
    case "PERSONAL_DATA":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};
