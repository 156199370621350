import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import "../../../styles/CommenStyle.css";
import { Modal } from 'react-bootstrap';
import * as actions from "../../../actions/clientDetails/ShareRegister";
import * as settings from "../../../actions/Settings";
import $ from "jquery";
import DatePicker from "react-datepicker";
import moment from "moment"; 



function EditShare(props) {

  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });
    const { getDocumentClassification, getSettingsShareTypeList,sendValues } = props;
    // getShareTypeList();
    getDocumentClassification();
    getSettingsShareTypeList();
    sendValues("documentLink", '');
  }, []);

  const addShareFunc = (e) => {
    var userData = JSON.parse(localStorage.getItem("userData"));
    e.preventDefault();
    let document_date;
    if(props.documentDate != ''){
      document_date= moment(props.documentDate).format('YYYY-MM-DD')
    }
    let input = {
      company_id: userData.company_id,
      id:props.shareId,
      client_id: sessionStorage.getItem("personalId"),
      resolution_date: moment(props.resolutionDate).format("YYYY-MM-DD"),
      acrafileddate:moment(props.acrafileddate).format("YYYY-MM-DD"),
      share_holder: props.shareHolder,
      share_type: props.shareType,
      value_per_share: props.valuePerShare,
      no_of_shares_held: props.noOfSharesHeld,
      remarks: props.remark,
      document_link: props.documentLink,
      document_id: props.documentId,
      attachment: props.attachMent,
      attachment_keyword: props.attachmentKeyword,
      document_type: props.documentType,
      document_date: document_date,
      notes: props.notes,
      active_tab: props.pageSelected,
    };
    props.editShareRegister(input);
  };

    return (
      <Modal
      show={props.editShares}
      // onHide={() => props.sendValues("addShares", false)}
      className="modal bootstrap-dialog type-primary fade size-normal "
      role="dialog"
      aria-hidden="true"
      id="CreateRegister"
      aria-labelledby="CreateRegister"
      tabIndex={-1}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bootstrap-dialog-draggable">
            <div className="bootstrap-dialog-header">
              <div
                className="bootstrap-dialog-close-button"
                style={{ display: "none" }}
              >
                <button className="close" aria-label="close">
                  ×
                </button>
              </div>
              <div
                className="bootstrap-dialog-title"
                id="e82ddc45-1abc-46f5-9283-744faa1591ab_title"
              >
                Edit
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">
                <div>
                  <form
                    acceptCharset="UTF-8"
                    encType="multipart/form-data"
                    onSubmit={(e) => addShareFunc(e)}
                    id="form"
                    className="form-horizontal"
                  >
                    <input
                      name="_token"
                      type="hidden"
                      defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446"
                    />
                    <div className="form-group">
                      <label
                        htmlFor="recorddate"
                        className="control-label col-sm-4 required"
                      >
                        Resolution Date
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.resolutionDate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("resolutionDate", Date);
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() =>
                              props.sendValues("resolutionDate", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>


                    <div className="form-group">
                      <label
                        htmlFor="recorddate"
                        className="control-label col-sm-4 required"
                      >
                       Acra Filed Date
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.acrafileddate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("acrafileddate", Date);
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() =>
                              props.sendValues("acrafileddate", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>


                    <div className="form-group">
                      <label
                        htmlFor="clientemployee_id"
                        className="control-label col-sm-4 required"
                      >
                        Share Holder
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            props.sendValues("shareHolder", e.target.value)
                          }
                          required="required"
                          id="clientemployee_id"
                          name="clientemployee_id"
                          value={props.shareHolder}
                        >
                          <option value="" selected="selected">
                            === Select Shareholder ===
                          </option>
                          {props.shareHolderList &&
                            props.shareHolderList.map((val) => {
                              return <option value={val.id}>{val.name}</option>;
                            })}
                        </select>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="sharetype_id"
                        className="control-label col-sm-4 required"
                      >
                        Share Type
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            props.sendValues("shareType", e.target.value)
                          }
                          style={{ backgroundColor: "#fff" }}
                          required="required"
                          id="sharetype_id"
                          name="sharetype_id"
                          value={props.shareType}
                        >
                          <option value="" selected="selected">
                            === Please Select ===
                          </option>
                          {props.List.map((shar) => {
                            return <option value={shar.id}>{shar.Name}</option>;
                          })}
                        </select>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="valuepershare"
                        className="control-label col-sm-4 required"
                      >
                        Value Per Share
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          onChange={(e) =>
                            props.sendValues("valuePerShare", e.target.value)
                          }
                          
                         
                          required="required"
                          value={props.valuePerShare}
                          name="valuepershare"
                          type="number"
                          id="valuepershare"
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="noofsharesheld"
                        className="control-label col-sm-4 required"
                      >
                        No of Shares Held
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          onChange={(e) =>
                            props.sendValues("noOfSharesHeld", e.target.value)
                          }
                          step={1}
                          required="required"
                          min={1}
                          name="noofsharesheld"
                          type="number"
                          id="noofsharesheld"
                          value={props.noOfSharesHeld}
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="remarks"
                        className="control-label col-sm-4"
                      >
                        Remarks
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          value={props.remark}
                          onChange={(e) =>
                            props.sendValues("remark", e.target.value)
                          }
                          rows={3}
                          name="remarks"
                          cols={50}
                          id="remarks"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="document_link"
                        className="control-label col-sm-4"
                      >
                        Document Link
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            props.sendValues("documentLink", e.target.value)
                          }
                          id="document_link"
                          name="document_link"
                          value={props.documentLink}
                        >
                          <option value="">View from the drop-down list / Select New Doc to upload new</option>
                          <option value={0}>New Document</option>
                          {props.DocumentTypeList.map((val) => {
                            return <option value={val.id}>{val.Name}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    {props.documentLink === "0" ? (
                      <div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_id"
                            className="control-label col-sm-4"
                          >
                            Document ID
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues("documentId", e.target.value)
                              }
                              name="document_id"
                              type="text"
                              id="document_id"
                              value={props.documentId}
                            />
                            <p className="help-block">
                              Must be unique ID with this client{" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="original_filename"
                            className="control-label col-sm-4"
                          >
                            Attachment
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              onChange={(e) => {
                                console.log("file", e.target.files[0]);
                                props.sendValues(
                                  "attachMent",
                                  e.target.files[0]
                                );
                              }}
                              maxLength={4096}
                              accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif"
                              name="original_filename"
                              type="file"
                              id="original_filename"
                              
                            />
                            <p className="help-block">
                              * Uploading of file limited to 2MB only.{" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="keywords"
                            className="control-label col-sm-4"
                          >
                            Attachment Keyword for Searching Purposes
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues(
                                  "attachmentKeyword",
                                  e.target.value
                                )
                              }
                              rows={3}
                              name="keywords"
                              cols={50}
                              value={props.attachmentKeyword}
                              id="keywords"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_type_id"
                            className="control-label col-sm-4"
                          >
                            Document Type
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues("documentType", e.target.value)
                              }
                              value={props.documentType}
                              id="document_type_id"
                              name="document_type_id"
                            >
                              <option value="" selected="selected">
                                === Select ===
                              </option>
                              {props.DocumentTypeList.map((val) => {
                                return (
                                  <option value={val.id}>{val.Name}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_date"
                            className="control-label col-sm-4"
                          >
                            Resolution/Document Date
                          </label>
                          <div className="col-sm-8">
                            <DatePicker
                              showPopperArrow={false}
                              dateFormat={"dd/MM/yyyy"}
                              selected={props.documentDate}
                              locale={"en"}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: "0px, 0px",
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: "viewport",
                                },
                              }}
                              onChange={(Date) => {
                                props.sendValues("documentDate", Date);
                              }}
                            >
                              <div
                                class="trash-icon"
                                onClick={() =>
                                  props.sendValues("documentDate", "")
                                }
                              >
                                <span class="glyphicon glyphicon-trash"></span>{" "}
                              </div>
                            </DatePicker>
                            <p className="help-block">DD/MM/YYYY </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_tag"
                            className="control-label col-sm-4"
                          >
                            Notes
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              onChange={(e) =>
                                props.sendValues("notes", e.target.value)
                              }
                              value={props.notes}
                              rows={3}
                              name="document_tag"
                              cols={50}
                              id="document_tag"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="client_id_doc"
                        type="hidden"
                        defaultValue={489}
                      />
                    </div>
                    <div className="form-group">
                      <div
                        className="text-danger lead strong col-xs-12 col-sm-12 col-md-12 col-lg-12"
                        id="errortext"
                      />
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="client_id"
                        type="hidden"
                        defaultValue={489}
                      />
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="operationtype"
                        type="hidden"
                        defaultValue="A"
                      />
                    </div>
                    <button
                      className="btn form-control btn-success"
                      type="submit"
                      value="A"
                      name="action"
                      id="action"
                    >
                      <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />{" "}
                      Update Share
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  className="btn form-control"
                  id="e3236b70-d42d-4c22-809e-7af2cf00fbce"
                  onClick={() =>{ props.sendValues("editShares", false)
                  props.sendValues("documentLink", '');
                }}
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    )
};

const mapStateToProps = (state, ownProps) => {
  const {
    editShares,
    resolutionDate,
    acrafileddate,
    shareHolder,
    shareType,
    valuePerShare,
    noOfSharesHeld,
    remark,
    documentLink,
    documentId,
    attachMent,
    attachmentKeyword,
    documentType,
    documentDate,
    notes,
    ShareTypeList,
    DocumentTypeList,
    shareHolderList,
    shareId,
  } = state.ShareReg;
  return {
    editShares,
    resolutionDate,
    acrafileddate,
    shareHolder,
    shareType,
    valuePerShare,
    noOfSharesHeld,
    remark,
    documentLink,
    documentId,
    attachMent,
    attachmentKeyword,
    documentType,
    documentDate,
    notes,
    ShareTypeList,
    DocumentTypeList,
    shareHolderList,
    shareId,
    personalList: state.Personal.personalList,
    pageSelected: state.Client.pageSelected,
    List: state.Settings.List,
  };

};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.share_register(k, v)),
    editShareRegister: (input) => dispatch(actions.editShareRegister(input)),
    getShareTypeList: () => dispatch(actions.getShareTypeList()),
    getDocumentClassification: () =>
      dispatch(actions.getDocumentClassification()),
    getSettingsShareTypeList: () =>
      dispatch(settings.getSettingsShareTypeList()),
    getDocumentClassification: () =>
      dispatch(actions.getDocumentClassification()),
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(EditShare);